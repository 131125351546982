<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-start">
        <div>
          <v-btn color="primary" text to="/penjualan/return" class="mr-3"
            ><v-icon large>mdi-arrow-left-bold</v-icon></v-btn
          >
        </div>
        <h2 class="mt-1">INPUT RETUR PENJUALAN</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col cols="auto" lg="8">
        <v-card outlined rounded="lg">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <!-- TANGGAL -->
                <v-menu
                  v-model="data.pickerTanggal"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      label="Tanggal"
                      :value="formatDate(data.tanggal)"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      outlined
                      background-color="light-blue lighten-5"
                      :disabled="staView"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.tanggal"
                    @input="data.pickerTanggal = false"
                  ></v-date-picker>
                </v-menu>
                <!-- PILIH CUSTOMER -->
                <div class="d-flex justify-space-between">
                  <v-text-field
                    :value="data.namaCustomer + ' - ' + data.kodeCustomer"
                    class="mr-2"
                    background-color="white"
                    dense
                    outlined
                    label="Customer"
                    readonly
                  ></v-text-field>
                  <v-btn
                    @click="showCustomer()"
                    :disabled="staView"
                    color="light-blue lighten-4"
                    ><v-icon>mdi-magnify</v-icon></v-btn
                  >
                </div>
                <div>
                  <v-btn-toggle
                    v-model="data.jenis"
                    color="deep-purple accent-3"
                    mandatory
                  >
                    <v-btn value="nota"> NOTA </v-btn>

                    <v-btn value="tanpa"> TANPA NOTA </v-btn>
                  </v-btn-toggle>
                </div>
              </v-col>
              <v-col cols="6">
                <v-textarea
                  v-model="data.keterangan"
                  rows="3"
                  dense
                  outlined
                  label="Keterangan"
                  background-color="light-blue lighten-5"
                  :readonly="staView"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="4">
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card elevation="0" class="text-h3 font-weight-bold pa-3">
                  <div class="d-flex justify-space-around">
                    <div>Rp.</div>
                    <v-spacer></v-spacer>
                    <div>{{ formatNumber(subtotal()) }}</div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <!-- TABEL ITEMS -->
      <v-col cols="auto" lg="8">
        <v-card outlined rounded="lg">
          <v-card-title class="d-flex justify-space-between">
            <div>
              <div>
                <v-btn
                  color="success"
                  rounded
                  @click="showForm()"
                  class="mr-2"
                  :disabled="
                    data.kodeCustomer == '' ||
                    staView ||
                    data.itemsOutstand.length > 0
                  "
                  small
                  ><v-icon>mdi-plus-thick</v-icon>Tambah Data</v-btn
                >
              </div>
              <div
                v-if="data.kodeCustomer == ''"
                class="d-flex justify-center text-caption"
              >
                Pilih customer Dahulu
              </div>
            </div>

            <v-spacer></v-spacer>

            <div>
              <v-text-field
                label="Pencarian"
                dense
                outlined
                hide-details
              ></v-text-field>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card outlined rounded="lg">
              <v-data-table
                :headers="data.headers"
                :items="data.items"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.jumlah`]="{ item }">
                  {{ formatNumber(item.jumlah) }}
                </template>
                <template v-slot:[`item.hargaBeli`]="{ item }">
                  {{ formatNumber(item.hargaBeli) }}
                </template>
                <template v-slot:[`item.subtotal`]="{ item }">
                  {{ formatNumber(item.jumlah * item.hargaBeli) }}
                </template>
                <template v-slot:[`item.opsi`]="{ index }">
                  <v-btn
                    color="error"
                    text
                    @click="hapusItem(index)"
                    :disabled="staView"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                  >
                  <v-btn
                    color="primary"
                    text
                    @click="showUpdateItem(index)"
                    :disabled="staView"
                    ><v-icon>mdi-pen</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-card>
            <v-divider></v-divider>
            <div class="d-flex justify-space-around mt-2">
              <v-btn @click="resetAll()" class="mr-2"
                ><v-icon>mdi-refresh</v-icon> BARU</v-btn
              >
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="warning"
                @click="updateData()"
                v-if="staView"
                :disabled="!staValid()"
                >SIMPAN<v-icon>mdi-floppy</v-icon>
              </v-btn> -->
              <v-btn
                color="success"
                @click="createData()"
                v-if="!staView"
                :loading="data.loading"
                :disabled="!staValid() || data.loading"
                ><v-icon>mdi-floppy</v-icon>SIMPAN
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- END TABEL ITEMS -->

      <!-- PLOT PIUTANG -->
      <v-col>
        <v-card outlined>
          <v-card-title class="d-flex justify-space-around">
            <div>
              <v-btn
                @click="showOutstand()"
                color="light-blue lighten-4"
                :disabled="staView"
                small
                class="mr-2"
                ><v-icon>mdi-card-bulleted-outline</v-icon></v-btn
              >
              <v-btn small @click="resetOutstand()" :disabled="staView"
                ><v-icon>mdi-refresh-circle</v-icon> RESET</v-btn
              >
              <div
                class="text-caption"
                v-if="data.itemsOutstand.length == 0 && !staView"
              >
                Pilih Nota Outstanding
              </div>
            </div>
            <v-spacer></v-spacer>
            <div>{{ formatNumber(sumAllNilai()) }}</div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headersOutstand"
                :items="data.itemsOutstand"
                dense
                hide-default-footer
                :items-per-page="100"
              >
                <template v-slot:[`item.sisa`]="{ item }">
                  {{ formatNumber(item.sisa) }}
                </template>
                <template v-slot:[`item.nilai`]="{ item }">
                  {{ formatNumber(item.nilai) }}
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="customer.dialog"
      max-width="1500px"
      transition="dialog-transition"
    >
      <v-card>
        <customer-view :staPilih="true" @customer="getCustomer"></customer-view>
      </v-card>
    </v-dialog>

    <!-- FORM TAMBAH DATA -->
    <v-dialog
      v-model="form.dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card outlined class="pb-4">
        <v-card-title class="py-2"> Form Detail </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <v-form v-model="form.isValid">
            <div class="d-flex justify-space-between">
              <v-text-field
                :value="barang.nama_bahan + ' - ' + barang.kode_bahan"
                label="Nama Bahan"
                dense
                outlined
                class="mr-2"
              ></v-text-field>
              <v-btn @click="showBarang()" color="light-blue lighten-4"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
            </div>
            <v-textarea
              v-model="form.keterangan"
              rows="2"
              :rules="rules.keterangan"
              label="Keterangan"
              dense
              outlined
              background-color="light-blue lighten-5"
            ></v-textarea>

            <v-row class="d-flex justify-space-between">
              <v-col>
                <v-text-field
                  v-model="form.jumlah"
                  :rules="rules.jumlah"
                  label="Jumlah"
                  dense
                  outlined
                  type="number"
                  background-color="light-blue lighten-5"
                ></v-text-field>
                <v-text-field
                  v-model="form.hargaBeli"
                  :rules="rules.hargaBeli"
                  label="Harga"
                  dense
                  outlined
                  type="number"
                  background-color="light-blue lighten-5"
                ></v-text-field>
                <v-text-field
                  :value="total()"
                  label="Total"
                  dense
                  readonly
                  type="number"
                  background-color="blue-grey lighten-5"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div class="mt-2 d-flex justify-end">
              <v-btn
                color="success"
                rounded
                small
                v-if="form.isEdit"
                :disabled="!form.isValid"
                @click="updateItem()"
                >Update<v-icon>mdi-pen</v-icon></v-btn
              ><v-btn
                color="success"
                rounded
                small
                v-else
                :disabled="!form.isValid"
                @click="tambahItem()"
                >Tambahkan<v-icon>mdi-plus-box</v-icon></v-btn
              >
            </div>
          </v-form>
        </v-card-text>
      </v-card>
      <!-- FORM TAMBAH DATA -->

      <!-- DIALOG BARANG -->
      <v-dialog
        v-model="barang.dialog"
        max-width="1000px"
        transition="dialog-transition"
      >
        <v-card>
          <barang-view
            jenis="masuk"
            :staPilih="true"
            @barang="getBarang"
          ></barang-view>
        </v-card>
      </v-dialog>
    </v-dialog>

    <!-- CUSTOMER OUTSTAND -->
    <v-dialog
      v-model="customer.dialogOutstand"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card class="pb-5">
        <v-card-title>
          <div v-if="data.xjenis == 2">
            DATA PIUTANG CUSTOMER : {{ this.data.nama_akun }}
          </div>
          <div v-if="data.xjenis == 3">
            DATA HUTANG SUPPLIER : {{ this.data.nama_akun }}
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="8">
              <v-card outlined>
                <v-data-table
                  :headers="customer.headersOutstand"
                  :items="customer.itemsOutstand"
                  :loading="customer.loadingOutstand"
                  :search="customer.searchOutstand"
                  dense
                  :items-per-page="50"
                  hide-default-footer
                >
                  <template v-slot:[`item.sisa`]="{ item }">
                    {{ formatNumber(item.sisa) }}
                  </template>
                  <template v-slot:[`item.bayar`]="{ item }">
                    {{ formatNumber(item.bayar) }}
                  </template>
                  <template v-slot:[`item.nilai`]="{ item }">
                    {{ formatNumber(item.nilai) }}
                  </template>
                  <template v-slot:[`item.kurang`]="{ item }">
                    {{ formatNumber(item.kurang) }}
                  </template>
                  <template v-slot:[`item.opsi`]="{ index }">
                    <v-btn color="primary" @click="updateNilai(index)" text
                      ><v-icon large>mdi-pencil-box-outline</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-card>
              <div class="d-flex justify-end mt-2">
                <table>
                  <tbody>
                    <strong>
                      <tr>
                        <td>Total Bayar</td>
                        <td class="px-1">:</td>
                        <td align="right">{{ formatNumber(sumNilai()) }}</td>
                      </tr>
                      <tr>
                        <td>Total Sisa Jumlah</td>
                        <td class="px-1">:</td>
                        <td align="right">
                          {{ formatNumber(sumSisaJumlah()) }}
                        </td>
                      </tr>
                    </strong>
                  </tbody>
                </table>
              </div>
            </v-col>

            <v-col cols="4">
              <v-card outlined>
                <v-card-text>
                  <v-text-field
                    v-model="customer.noBukti"
                    label="No. Bukti"
                    dense
                    outlined
                    readonly
                    background-color="blue-grey lighten-5"
                  ></v-text-field>
                  <v-text-field
                    v-model="customer.outstand"
                    label="Outstand"
                    dense
                    outlined
                    readonly
                    background-color="blue-grey lighten-5"
                  ></v-text-field>
                  <v-text-field
                    v-model="customer.nilai"
                    label="Nilai"
                    dense
                    outlined
                    v:onv-on:keyup.13="applyNilai()"
                    @input="changeKurang()"
                    background-color="light-blue lighten-5"
                  ></v-text-field>
                  <v-text-field
                    v-model="customer.kurang"
                    label="Kurang"
                    dense
                    outlined
                    readonly
                    background-color="blue-grey lighten-5"
                  ></v-text-field>
                  <v-btn
                    color="success"
                    outlined
                    rounded
                    @click="applyNilai()"
                    small
                    >Terapkan</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="my-2"></v-divider>
          <div class="d-flex justify-end">
            <v-btn color="success" outlined @click="setOutstand()">OK</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- END CUSTOMER OUTSTAND -->

    <notifications position="bottom right"></notifications>
  </v-container>
</template>

<script>
import CustomerView from "@/views/Master/CustomerView.vue";
import BarangView from "@/views/Master/BarangView.vue";
import axios from "axios";
import swal from "sweetalert";

export default {
  components: { CustomerView, BarangView },
  data() {
    return {
      data: {
        jenis: "",
        headers: [
          { text: "KODE", value: "kode_bahan", divider: true },
          { text: "NAMA BARANG", value: "nama_bahan", divider: true },
          { text: "JUMLAH", value: "jumlah", align: "right", divider: true },
          // { text: "STOCK", value: "stok", align: "right", divider: true },
          {
            text: "HARGA RETUR (Rp)",
            value: "hargaBeli",
            align: "right",
            divider: true,
          },
          {
            text: "SUBTOTAL",
            value: "subtotal",
            align: "right",
            divider: true,
          },
          { text: "KETERANGAN", value: "keterangan", divider: true },
          { text: "OPSI", value: "opsi" },
        ],
        items: [
          // {
          //   kode_bahan: "01-0001",
          //   nama_bahan: "Kacang Atom (2,3 Kg)",
          //   jumlah: "10",
          //   stok: 0,
          //   hargaBeli: "20000",
          //   rp_jual: undefined,
          //   keterangan: "123",
          //   isValid: true,
          //   dialog: true,
          //   isEdit: false,
          //   indexEdit: "",
          // },
        ],
        jumlah: 0,
        loading: false,
        search: "",
        kodeCustomer: "",
        namaCustomer: "",
        pickerTanggal: false,
        pickerTglKirim: false,
        tanggal: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        tglKirim: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        keterangan: "",
        potongan: 0,
        no_retur: "",
        user: this.$store.state.auth.user.name,
        headersOutstand: [
          { text: "No. Bukti", value: "no_bukti" },
          { text: "Outstand", value: "sisa", align: "right" },
          { text: "Jumlah", value: "nilai", align: "right" },
        ],
        itemsOutstand: [],
      },
      customer: {
        dialog: false,
        listOutstand: false,
        dialogOutstand: false,
        headersOutstand: [
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "OUTSTAND", value: "sisa", divider: true, align: "right" },
          { text: "BAYAR", value: "bayar", divider: true, align: "right" },
          { text: "JUMLAH", value: "nilai", divider: true, align: "right" },
          { text: "SISA", value: "kurang", divider: true, align: "right" },
          // { text: "NO. BUKTI", value: "noBukti", divider: true },
          { text: "OPSI", value: "opsi" },
        ],
        itemsOutstand: [{ sisa: 0, nilai: 0, kurang: 0, noBukti: "" }],
        loadingOutstand: false,
        searchOutstand: "",
        dialogBayar: false,
        indexOutstand: 0,
        sisaJumlah: 0,

        noBukti: "",
        outstand: 0,
        nilai: 0,
        kurang: 0,
      },
      form: {
        kode_bahan: "",
        nama_bahan: "",
        jumlah: 0,
        stok: 0,
        hargaBeli: "",
        rp_jual: "",
        keterangan: "",
        isValid: false,
        dialog: false,
        isEdit: false,
        indexEdit: "",
      },
      rules: {
        kode_bahan: [(v) => v != "" || "Pilih Nama Bahan"],
        jumlah: [(v) => v > 0 || "Jumlah harus lebih dari 0"],
        hargaBeli: [(v) => v > 0 || "Harga Beli harus lebih dari 0"],
        keterangan: [(v) => v > "" || "Keterangan Harus Diisi"],
      },
      barang: {
        dialog: false,
        kode_bahan: "",
        nama_bahan: "",
        stok: "",
        rp_jual: "",
      },
      po: {
        dialog: false,
        kd_customer: "",
        nama: "",
        doc: {
          kd_customer: "",
          nama: "",
        },
        detail: [],
      },
      staView: false,
      staUpdate: false,
    };
  },
  mounted() {
    console.log(this.$route.query.noRetur);
    if (
      this.$route.query.noRetur != "" &&
      this.$route.query.noRetur != undefined
    ) {
      this.data.no_retur = this.$route.query.noRetur;
      this.loadRetur();
    }
  },
  methods: {
    async showOutstand() {
      if (this.subtotal() == 0) {
        this.$notify({
          type: "warning",
          text: "Isikan Detail Item Retur Terlebih Dahulu",
        });
        return;
      }

      this.customer.dialogOutstand = true;
      this.customer.sisaJumlah = this.data.jumlah;

      //JIKA PIUTANG CUSTOMER
      await axios
        .post("penjualan/piutang/customer", {
          kode: this.data.kodeCustomer,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.customer.itemsOutstand = res.data.data;
          this.customer.indexOutstand = 0;
          this.customer.outstand = 0;
          this.customer.nilai = 0;
          this.customer.kurang = 0;
          for (let i = 0; i < this.customer.itemsOutstand.length; i++) {
            this.customer.itemsOutstand[i].nilai = 0;
            this.customer.itemsOutstand[i].kurang = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateNilai(index) {
      this.customer.indexOutstand = index;
      this.customer.noBukti = this.customer.itemsOutstand[index].no_bukti;
      this.customer.outstand = this.customer.itemsOutstand[index].outstand;
      this.customer.nilai = parseInt(this.customer.itemsOutstand[index].nilai);
      this.customer.kurang = this.customer.itemsOutstand[index].kurang;
      this.customer.outstand = this.customer.itemsOutstand[index].sisa;
    },
    sumSisaJumlah() {
      return this.subtotal() - this.sumNilai();
    },
    sumNilai() {
      let a = 0;
      for (let i = 0; i < this.customer.itemsOutstand.length; i++) {
        if (isNaN(this.customer.itemsOutstand[i].nilai) == false) {
          a = a + this.customer.itemsOutstand[i].nilai * 1;
        }
      }
      return a;
    },
    changeKurang() {
      this.customer.kurang = this.customer.outstand - this.customer.nilai;
      if (this.customer.kurang < 0) {
        this.customer.nilai = 0;
        this.customer.kurang = 0;
      }
    },
    applyNilai() {
      if (this.customer.nilai == 0) {
        this.$notify({ type: "warning", text: "Nilai Masih Kosong" });
        return;
      }
      if (this.customer.nilai > this.sumSisaJumlah()) {
        this.$notify({ type: "warning", text: "Nilai Melebihi Sisa Jumlah" });
        return;
      }

      let i = this.customer.indexOutstand;
      this.customer.itemsOutstand[i].nilai = this.customer.nilai;
      this.customer.itemsOutstand[i].kurang = this.customer.kurang;

      this.customer.noBukti = "";
      this.customer.indexOutstand = 99;
      this.customer.outstand = 0;
      this.customer.nilai = 0;
      this.customer.kurang = 0;
    },
    setOutstand() {
      this.customer.dialogOutstand = false;
      this.data.itemsOutstand = this.customer.itemsOutstand;
    },
    sumAllNilai() {
      let a = 0;
      for (let i = 0; i < this.data.itemsOutstand.length; i++) {
        if (isNaN(this.data.itemsOutstand[i].nilai) == false) {
          a = a + parseFloat(this.data.itemsOutstand[i].nilai);
        }
      }
      return a;
    },
    resetOutstand() {
      this.data.itemsOutstand = [];
    },

    showUpdateItem(index) {
      this.form.indexEdit = index;
      this.form.isEdit = true;
      this.form.dialog = true;
      this.form.kode_bahan = this.data.items[index].kode_bahan;
      this.form.nama_bahan = this.data.items[index].nama_bahan;
      this.barang.kode_bahan = this.data.items[index].kode_bahan;
      this.barang.nama_bahan = this.data.items[index].nama_bahan;
      this.form.jumlah = this.data.items[index].jumlah;

      this.form.hargaBeli = this.data.items[index].hargaBeli;
      this.form.rp_jual = this.data.items[index].rp_jual;
      this.form.stok = this.data.items[index].stok;

      this.barang.rp_jual = this.data.items[index].rp_jual;
      this.barang.stok = this.data.items[index].stok;
      this.form.keterangan = this.data.items[index].keterangan;
    },
    updateItem() {
      if (this.barang.kode_bahan == "" || this.barang.kode_bahan == null) {
        this.$notify({ type: "error", text: "Pilih Nama Bahan" });
        return;
      }
      this.form.kode_bahan = this.barang.kode_bahan;
      this.form.nama_bahan = this.barang.nama_bahan;
      this.form.stok = this.barang.stok;
      this.form.rp_jual = this.barang.rp_jual;

      let i = this.form.indexEdit;
      this.data.items[i].kode_bahan = this.form.kode_bahan;
      this.data.items[i].nama_bahan = this.form.nama_bahan;
      this.data.items[i].jumlah = this.form.jumlah;
      this.data.items[i].hargaBeli = this.form.hargaBeli;
      this.data.items[i].rp_jual = this.form.rp_jual;
      this.data.items[i].stok = this.form.stok;
      this.data.items[i].keterangan = this.form.keterangan;

      this.resetForm();
      this.form.isEdit = false;
      this.form.dialog = false;
    },

    showPo() {
      this.po.dialog = true;
    },
    getPo(value) {
      this.po.no_po = value.no_po;
      this.data.no_po = value.no_po;
      this.po.dialog = false;
      this.loadPo();
    },
    async loadRetur() {
      await axios
        .post("penjualan/return/byretur", {
          no_retur: this.data.no_retur,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.staView = true;
          this.data.keterangan = res.data.data.keterangan;
          this.data.items = res.data.data.detail;
          this.data.kodeCustomer = res.data.data.kode_customer;
          this.data.namaCustomer = res.data.data.nama;
          this.data.tanggal = res.data.data.tanggal;
          this.data.itemsOutstand = res.data.data.nota;

          this.$notify({ type: "success", text: res.data.message });
        });
    },
    showCustomer() {
      this.customer.dialog = true;
    },

    getCustomer(value) {
      this.data.kodeCustomer = value.kode;
      this.data.namaCustomer = value.nama;
      this.customer.dialog = false;
    },
    showForm() {
      this.form.dialog = true;
    },
    showBarang() {
      this.barang.dialog = true;
    },
    getBarang(value) {
      for (let i = 0; i < this.data.items.length; i++) {
        if (value.kode_bahan == this.data.items[i].kode_bahan) {
          this.$notify({
            type: "error",
            text: "Bahan dengan Keteranan Sudah Ada, mohon koreksi kembali",
          });
          return;
        }
      }
      this.barang.kode_bahan = value.kode_bahan;

      this.barang.nama_bahan = value.nama_bahan;
      this.barang.stok = value.stok;
      this.barang.rp_jual = value.rp_jual;
      this.barang.dialog = false;
    },
    tambahItem() {
      if (this.barang.kode_bahan == "" || this.barang.kode_bahan == null) {
        this.$notify({ type: "error", text: "Pilih Nama Bahan" });
        return;
      }
      for (let i = 0; i < this.data.items.length; i++) {
        if (this.barang.kode_bahan == this.data.items[i].kode_bahan) {
          this.$notify({
            type: "error",
            text: "Bahan Sudah Ada, mohon koreksi kembali",
          });
          return;
        }
      }
      this.form.kode_bahan = this.barang.kode_bahan;
      this.form.nama_bahan = this.barang.nama_bahan;
      this.form.stok = this.barang.stok;
      this.form.rp_jual = this.barang.rp_jual;
      this.data.items.push({ ...this.form });
      this.resetForm();
      this.form.dialog = false;
      this.data.jumlah = this.subtotal();
    },
    resetAll() {
      this.staView = false;
      this.data.kodeCustomer = "";
      this.data.namaCustomer = "";
      this.data.keterangan = "";
      this.data.potongan = 0;
      this.data.no_po = "";
      this.barang.kode_bahan = "";
      this.barang.nama_bahan = "";
      this.barang.dialog = false;

      this.data.items = [];
      this.data.itemsOutstand = [];
      this.form = {
        kode_bahan: "",
        nama_bahan: "",
        jumlah: 0,
        stok: 0,
        hargaBeli: "",
        rp_jual: "",
        keterangan: "",
        dialog: false,
      };
      this.customer = {
        dialog: false,
        listOutstand: false,
        dialogOutstand: false,
        itemsOutstand: [{ sisa: 0, nilai: 0, kurang: 0, noBukti: "" }],
        loadingOutstand: false,
        searchOutstand: "",
        dialogBayar: false,
        indexOutstand: 0,
        sisaJumlah: 0,

        noBukti: "",
        outstand: 0,
        nilai: 0,
        kurang: 0,
      };
    },
    resetForm() {
      this.barang.kode_bahan = "";
      this.barang.nama_bahan = "";
      this.form = {
        kode_bahan: "",
        nama_bahan: "",
        jumlah: 0,
        stok: 0,
        hargaBeli: "",
        rp_jual: "",
        keterangan: "",
        dialog: false,
      };
    },
    total() {
      return this.form.jumlah * this.form.hargaBeli;
    },
    subtotal() {
      let a = 0;
      for (let i = 0; i < this.data.items.length; i++) {
        a = a + this.data.items[i].jumlah * this.data.items[i].hargaBeli;
      }
      return a;
    },
    bayar() {
      return this.subtotal() - this.data.potongan;
    },
    hapusItem(index) {
      this.data.items.splice(index, 1);
    },

    async createData() {
      if (this.data.kodeCustomer == "") {
        this.$notify({
          type: "error",
          text: "Mohon isi customer",
        });
        return;
      }
      this.data.jumlah = this.subtotal();
      this.data.loading = true;

      await axios
        .post("penjualan/return/create", this.data)
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.error });
            return;
          }
          swal("Sukses", res.data.message, "success");
          this.resetAll();
          this.$router.push("/penjualan/return");
        })
        .catch((err) => {
          swal("Gagal", "Ada masalah di client " + err, "error");
        });

      this.data.loading = false;
    },

    async updateData() {
      if (this.data.kodeCustomer == "") {
        this.$notify({
          type: "error",
          text: "Mohon isi customer",
        });
        return;
      }
      this.data.loading = true;
      await axios
        .post("pembelian/po/update", this.data)
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.error });
            return;
          }
          swal("Sukses", res.data.message, "success");
          this.resetAll();
          this.$router.push("/penjualan/return");
        })
        .catch((err) => {
          swal("Gagal", "Ada masalah di client " + err, "error");
        });
      this.data.loading = false;
    },

    staValid() {
      console.log(this.data.jenis, this.data.itemsOutstand.length);
      if (
        this.data.items.length == 0 ||
        (this.data.jenis == "nota" && this.data.itemsOutstand.length == 0) ||
        (this.data.jenis == "nota" && this.subtotal() != this.sumAllNilai())
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {},
};
</script>

<style></style>
