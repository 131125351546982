<template>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field
          outlined
          :value="jumlah"
          label="Jumlah"
          ref="jum"
          @input="changeJumlah()"
        ></v-text-field>
        <!-- {{ plus() }} -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      jumlah: 0,
    };
  },
  methods: {
    changeJumlah() {
      console.log(this.$refs.jum);
      this.jumlah = this.formatNumber(this.$refs.jum.internalValue);
    },
    // plus() {
    //     return 100 + this.jumlah
    // }
  },
};
</script>

<style>
</style>