<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>MANAJEMEN BASE URL AXIOS</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <v-card outlined rounded="lg">
          <v-card-text>
            <div class="d-flex justify-space-around">
              <v-text-field
                label="BASE URL AXIOS"
                v-model="baseUrl"
                dense
                outlined
                class="mr-4"
              ></v-text-field>
              <v-btn color="warning"
                ><v-icon class="mr-4">mdi-floppy</v-icon> UPDATE</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "",
    };
  },
  mounted() {
    this.loadData() 
  },
  methods: {
    async loadData() {
      
    }
  },
};
</script>

<style>
</style>