<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>LAPORAN</h2>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col cols="auto" lg="6" md="12" sm="12">
        <v-card>
          <v-card-title class="py-3 d-flex justify-space-around">
            <v-menu
              v-model="periode.pickerTanggal1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Tanggal Awal"
                  :value="formatDate(periode.tanggal1)"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  background-color="light-blue lighten-5"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="periode.tanggal1"
                @input="periode.pickerTanggal1 = false"
              ></v-date-picker>
            </v-menu>
            -
            <v-menu
              v-model="periode.pickerTanggal2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Tanggal Akhir"
                  :value="formatDate(periode.tanggal2)"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  background-color="light-blue lighten-5"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="periode.tanggal2"
                @input="periode.pickerTanggal2 = false"
              ></v-date-picker>
            </v-menu>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!-- LAPORAN STOK2 -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Stok</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="stokDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN PENERIMAAN -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Penerimaan</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="penerimaanDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN PENJUALAN -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Penjualan</div>
              <v-spacer></v-spacer>

              <v-btn color="success" rounded @click="penjualanDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN PENJUALAN & LABA RUGI -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Laba Penjualan</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="labarugiDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <!-- LAPORAN PIUTANG -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Piutang</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="piutangDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN HUTANG -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Hutang</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="hutangDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
          <v-divider></v-divider>
        </v-card>

        <!-- DATA DETAIL -->
        <v-card>
          <v-card-text>
            <!-- LAPORAN DETAIL STOCK -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Detail Stok</div>
              <v-spacer></v-spacer>
              <v-text-field
                outlined
                dense
                hide-details
                label="Nama Barang"
                id="id"
                class="mr-2"
                :value="
                  barang.nama_bahan + ' ' + barang.ukuran + ' ' + barang.unit
                "
              ></v-text-field>
              <v-btn
                color="light-blue lighten-4"
                class="mr-4"
                @click="showBarang()"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
              <v-btn color="success" rounded @click="detailStokDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN DETAIL PIUTANG -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Detail Piutang</div>

              <v-spacer></v-spacer>
              <v-text-field
                outlined
                dense
                hide-details
                label="Customer"
                id="id"
                class="mr-2"
                width="200"
                :value="customer.nama_customer"
              ></v-text-field>
              <v-btn
                color="light-blue lighten-4"
                class="mr-4"
                @click="showCustomer()"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >

              <v-btn color="success" rounded @click="detailPiutangDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>

            <v-divider class="my-2"></v-divider>
            <!-- LAPORAN DETAIL HUTANG -->
            <div class="d-flex justify-space-around align-center">
              <div>Laporan Detail Hutang</div>
              <v-spacer></v-spacer>
              <v-text-field
                outlined
                dense
                hide-details
                label="Supplier"
                id="id"
                class="mr-2"
                width="200"
                :value="supplier.nama_supplier"
              ></v-text-field>
              <v-btn
                color="light-blue lighten-4"
                class="mr-4"
                @click="showSupplier()"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
              <v-btn color="success" rounded @click="detailHutangDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="barang.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <barang-view
          jenis="masuk"
          :staPilih="true"
          @barang="getBarang"
        ></barang-view>
      </v-card>
    </v-dialog>

    <!-- SHOW CUSTOMER -->
    <v-dialog
      v-model="customer.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card outlined>
        <customer-view :staPilih="true" @customer="getCustomer"></customer-view>
      </v-card>
    </v-dialog>
    <!-- END SHOW CUSTOMER -->

    <v-dialog
      v-model="supplier.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <supplier-view :staPilih="true" @supplier="getSupplier"></supplier-view>
      </v-card>
    </v-dialog>
    <notifications position="bottom right"></notifications>
  </v-container>
</template>

<script>
import ExcelJS from "exceljs";
import axios from "axios";
import moment from "moment";
import BarangView from "../Master/BarangView.vue";
import CustomerView from "../Master/CustomerView.vue";
import SupplierView from "../Master/SupplierView.vue";
export default {
  components: {
    BarangView,
    CustomerView,
    SupplierView,
  },
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      loading: false,
      barang: {
        dialog: false,
        kode_bahan: "",
        nama_bahan: "",
        ukuran: "",
        unit: "",
      },
      customer: {
        dialog: false,
        kode_customer: "",
        nama_customer: "",
      },
      supplier: {
        dialog: false,
        kode_supplier: "",
        nama_supplier: "",
      },
    };
  },
  methods: {
    // STOK DOWNLOAD
    async stokDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/stok", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("REKAP");
        const titleRow = rekapsheet.addRow([
          "LAPORAN REKAP STOK - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "KODE JENIS",
          "NAMA JENIS",
          "SALDO AWAL (QTY)",
          "TERIMA (QTY)",
          "KELUAR (QTY)",
          "SALDO AKHIR (QTY)",
          "SALDO AWAL (RP)",
          "TERIMA (RP)",
          "KELUAR (RP)",
          "SALDO AKHIR (RP)",
          // "TOTAL (QTY)",
          // "TOTAL (RP)",
        ]);
        headerRow.font = { bold: true };
        response.data.rekap.forEach((item) => {
          rekapsheet.addRow([
            item.kd_jenis,
            item.nm_jenis,
            item.saldo_awal_qty,
            item.terima_qty,
            item.keluar_qty,
            item.saldo_akhir_qty,
            item.saldo_awal_rp,
            item.terima_rp,
            item.keluar_rp,
            item.saldo_akhir_rp,
            // item.total_qty,
            // item.total_rp,
            item.rp_jual,
          ]);
        });
        // END SHEET KHUSUS REKAP

        // SHEET KHUSUS DETAIL
        const datagroupJenis = [
          ...new Set(response.data.data.map((item) => item.nm_jenis)),
        ];
        const datasheet = workbook.addWorksheet("DETAIL");
        const datatitleRow = datasheet.addRow([
          "LAPORAN DATA STOK " +
            localStorage.getItem("kode_company") +
            " - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        datatitleRow.font = { bold: true };
        datasheet.mergeCells("A1:J1");
        datasheet.addRow([""]);
        const dataheaderRow = datasheet.addRow([
          "NAMA JENIS",
          "KODE BARANG",
          "NAMA BARANG",
          "UKURAN",
          "MERK",
          "SATUAN",
          "SALDO AWAL (QTY)",
          "TERIMA (QTY)",
          "KELUAR (QTY)",
          "SALDO AKHIR (QTY)",
          "SALDO AWAL (RP)",
          "TERIMA (RP)",
          "KELUAR (RP)",
          "SALDO AKHIR (RP)",
        ]);
        dataheaderRow.font = { bold: true };
        let datafilterData = [];

        datasheet.getColumn(1).width = 20;
        datasheet.getColumn(2).width = 15;
        datasheet.getColumn(3).width = 40;
        datasheet.getColumn(4).width = 15;
        datasheet.getColumn(5).width = 15;
        datasheet.getColumn(6).width = 15;
        datasheet.getColumn(7).width = 15;
        datasheet.getColumn(8).width = 15;
        datasheet.getColumn(9).width = 15;
        datasheet.getColumn(10).width = 15;
        datasheet.getColumn(11).width = 15;
        datasheet.getColumn(12).width = 15;
        datasheet.getColumn(13).width = 15;
        datasheet.getColumn(14).width = 15;

        datasheet.getColumn(7).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(8).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(9).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(10).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(11).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(12).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(13).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        datasheet.getColumn(14).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        let sumSaldoawal_qty = 0;
        let sumTerima_qty = 0;
        let sumKeluar_qty = 0;
        let sumSaldoakhir_qty = 0;
        let sumSaldoawal_rp = 0;
        let sumTerima_rp = 0;
        let sumKeluar_rp = 0;
        let sumSaldoakhir_rp = 0;
        for (let i = 0; i < datagroupJenis.length; i++) {
          datafilterData = response.data.data.filter(function (el) {
            return el.nm_jenis == datagroupJenis[i];
          });
          let nSaldoawal_qty = 0;
          let nTerima_qty = 0;
          let nKeluar_qty = 0;
          let nSaldoakhir_qty = 0;

          let nSaldoawal_rp = 0;
          let nTerima_rp = 0;
          let nKeluar_rp = 0;
          let nSaldoakhir_rp = 0;
          datafilterData.forEach((item) => {
            datasheet.addRow([
              item.nm_jenis,
              item.kode_bahan,
              item.nama_bahan,
              item.ukuran,
              item.merk,
              item.unit,
              item.saldo_awal_qty,
              item.terima_qty,
              item.keluar_qty,
              item.saldo_akhir_qty,
              item.saldo_awal_rp,
              item.terima_rp,
              item.keluar_rp,
              item.saldo_akhir_rp,
            ]);
            nSaldoawal_qty = nSaldoawal_qty + item.saldo_awal_qty;
            nTerima_qty = nTerima_qty + item.terima_qty;
            nKeluar_qty = nKeluar_qty + item.keluar_qty;
            nSaldoakhir_qty = nSaldoakhir_qty + item.saldo_akhir_qty;

            nSaldoawal_rp = nSaldoawal_rp + item.saldo_awal_rp;
            nTerima_rp = nTerima_rp + item.terima_rp;
            nKeluar_rp = nKeluar_rp + item.keluar_rp;
            nSaldoakhir_rp = nSaldoakhir_rp + item.saldo_akhir_rp;

            sumSaldoawal_qty = sumSaldoawal_qty + item.saldo_awal_qty;
            sumTerima_qty = sumTerima_qty + item.terima_qty;
            sumKeluar_qty = sumKeluar_qty + item.keluar_qty;
            sumSaldoakhir_qty = sumSaldoakhir_qty + item.saldo_akhir_qty;
            sumSaldoawal_rp = sumSaldoawal_rp + item.saldo_awal_rp;
            sumTerima_rp = sumTerima_rp + item.terima_rp;
            sumKeluar_rp = sumKeluar_rp + item.keluar_rp;
            sumSaldoakhir_rp = sumSaldoakhir_rp + item.saldo_akhir_rp;
          });

          datasheet.addRow([
            "",
            "",
            "Jumlah",
            nSaldoawal_qty,
            nTerima_qty,
            nKeluar_qty,
            nSaldoakhir_qty,
            nSaldoawal_rp,
            nTerima_rp,
            nKeluar_rp,
            nSaldoakhir_rp,
          ]);
          datasheet.addRow([""]);
        }

        let dataSumRow = datasheet.addRow([
          "",
          "",
          "Jumlah",
          sumSaldoawal_qty,
          sumTerima_qty,
          sumKeluar_qty,
          sumSaldoakhir_qty,
          sumSaldoawal_rp,
          sumTerima_rp,
          sumKeluar_rp,
          sumSaldoakhir_rp,
        ]);
        dataSumRow.font = { bold: true };

        // END SHEET KHUSUS DETAIL

        // const groupJenis = [
        //   ...new Set(response.data.data.map((item) => item.nm_jenis)),
        // ];
        // let worksheet = "";
        // let filterData = [];
        // for (let i = 0; i < groupJenis.length; i++) {
        //   worksheet = workbook.addWorksheet(groupJenis[i]);

        //   filterData = response.data.data.filter(function (el) {
        //     return el.nm_jenis == groupJenis[i];
        //   });
        //   const titleRow = worksheet.addRow([
        //     "LAPORAN STOK : " +
        //       localStorage.getItem("kode_company") +
        //       " - PERIODE : " +
        //       moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
        //       " s/d " +
        //       moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        //   ]);
        //   titleRow.font = { bold: true };
        //   worksheet.addRow([""]);

        //   const headerRow = worksheet.addRow([
        //     "KODE BARANG",
        //     "NAMA BARANG",
        //     "UNIT",
        //     "UKURAN",
        //     "SALDO AWAL (QTY)",
        //     "TERIMA (QTY)",
        //     "KELUAR (QTY)",
        //     "SALDO AKHIR (QTY)",
        //     "SALDO AWAL (RP)",
        //     "TERIMA (RP)",
        //     "KELUAR (RP)",
        //     "SALDO AKHIR (RP)",
        //     // "TOTAL (QTY)",
        //     // "TOTAL (RP)",
        //     "HARGA DISTRIBUTOR",
        //     "HARGA STAR OUTLET",
        //     "HARGA GROSIR",
        //     "HARGA RETAIL",
        //   ]);
        //   headerRow.font = { bold: true };
        //   // Add fetched data to the worksheet
        //   filterData.forEach((item) => {
        //     worksheet.addRow([
        //       item.kode_bahan,
        //       item.nama_bahan,
        //       item.unit,
        //       item.ukuran,
        //       item.saldo_awal_qty,
        //       item.terima_qty,
        //       item.keluar_qty,
        //       item.saldo_akhir_qty,
        //       item.saldo_awal_rp,
        //       item.terima_rp,
        //       item.keluar_rp,
        //       item.saldo_akhir_rp,
        //       // item.total_qty,
        //       // item.total_rp,
        //       item.rp_distributor,
        //       item.rp_staroutlet,
        //       item.rp_grosir,
        //       item.rp_retail,
        //     ]);
        //   });
        // }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Stok " +
            localStorage.getItem("kode_company") +
            " Periode : " +
            this.periode.tanggal1 +
            " - " +
            this.periode.tanggal2 +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // PENERIMAAN DONWLOAD
    async penerimaanDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/penerimaan", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("REKAP");
        const titleRow = rekapsheet.addRow([
          "LAPORAN REKAP PENERIMAAN STOK - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "KODE JENIS",
          "NAMA JENIS",
          "TOTAL (QTY)",
          "TOTAL (RP)",
        ]);
        headerRow.font = { bold: true };
        response.data.rekap.forEach((item) => {
          rekapsheet.addRow([
            item.kd_jenis,
            item.nm_jenis,
            item.tot_qty,
            item.tot_rp,
          ]);
        });
        // END SHEET KHUSUS REKAP

        const groupJenis = [
          ...new Set(response.data.data.map((item) => item.nm_jenis)),
        ];
        let worksheet = "";
        let filterData = [];
        for (let i = 0; i < groupJenis.length; i++) {
          worksheet = workbook.addWorksheet(groupJenis[i]);

          filterData = response.data.data.filter(function (el) {
            return el.nm_jenis == groupJenis[i];
          });

          // TITLE
          const titleRow = worksheet.addRow([
            "LAPORAN PENERIMAAN - PERIODE : " +
              moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
              " s/d " +
              moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
          ]);
          titleRow.font = { bold: true };
          worksheet.addRow([""]);

          // HEADER
          const headerRow = worksheet.addRow([
            "TANGGAL",
            "NO BUKTI",
            "NO PO",
            "KODE",
            "NAMA BARANG",
            "UKURAN",
            "NAMA JENIS",
            "JUMLAH",
            "HARGA",
            "TOTAL",
            "SUPPLIER",
            "NO SJ",
            "TGL SJ",
            "KETERANGAN",
          ]);
          headerRow.font = { bold: true };

          //FETCH DETAIL
          filterData.forEach((item) => {
            worksheet.addRow([
              item.tgl_terima,
              item.no_bukti,
              item.no_po,
              item.kode_bahan,
              item.nama_bahan,
              item.ukuran,
              item.nm_jenis,
              item.jml_terima,
              item.harga,
              item.subtotal,
              item.nama,
              item.no_sj,
              item.tgl_sj,
              item.keterangan,
            ]);
          });
        }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Penerimaan Periode " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " - " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // PENJUALAN
    async penjualanDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/penjualan", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet("DETAIL");

        // TITLE
        const titleRow = worksheet.addRow([
          "LAPORAN PENJUALAN " +
            localStorage.getItem("kode_company") +
            " - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        worksheet.addRow([""]);

        // HEADER
        const headerRow = worksheet.addRow([
          "TGL. NOTA",
          "TGL. VALIDASI",
          "NO FAKTUR",
          "KODE CUSTOMER",
          "CUSTOMER",
          "KODE OUTLET",
          "TIPE CUSTOMER",
          "ALAMAT",
          "SISTEM",
          "NAMA SALES",
          "KODE PRODUK",
          "NAMA PRODUK",
          "ITEM PRODUK",
          "MERK PRODUK",
          "SATUAN",
          "JUMLAH",
          "HARGA JUAL",
          "DISKON",
          "SUBTOTAL",
        ]);
        headerRow.font = { bold: true };

        //FETCH DETAIL
        response.data.data.forEach((item) => {
          worksheet.addRow([
            moment(String(item.tanggal)).format("DD-MM-YYYY"),
            moment(String(item.tgl_validasi)).format("DD-MM-YYYY"),
            item.no_bukti,
            item.kd_customer,
            item.nama,
            item.kode_outlet,
            item.jenis,
            item.alamat,
            item.pembayaran,
            item.sales,
            item.kode_bahan,
            item.nama_bahan,
            item.ukuran,
            item.merk,
            item.unit,
            item.jumlah,
            item.rp_jual,
            item.diskon,
            item.subtotal,
          ]);
        });

        worksheet.mergeCells("A1:E1");
        worksheet.getColumn(14).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        worksheet.getColumn(15).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        worksheet.getColumn(16).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        worksheet.getColumn(17).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        const groupSales = [
          ...new Set(response.data.data.map((item) => item.sales)),
        ];
        let salessheet = "";
        let filterData = [];
        for (let i = 0; i < groupSales.length; i++) {
          salessheet = workbook.addWorksheet(groupSales[i]);

          filterData = response.data.data.filter(function (el) {
            return el.sales == groupSales[i];
          });

          // TITLE
          const titleRow = salessheet.addRow([
            "LAPORAN SALES " +
              groupSales[i] +
              " - PERIODE : " +
              moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
              " s/d " +
              moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
          ]);
          titleRow.font = { bold: true };
          salessheet.addRow([""]);

          // HEADER
          const headerRow = salessheet.addRow([
            "TGL. NOTA",
            "TGL. VALIDASI",
            "NO FAKTUR",
            "KODE CUSTOMER",
            "CUSTOMER",
            "KODE OUTLET",
            "TIPE CUSTOMER",
            "ALAMAT",
            "SISTEM",
            "NAMA SALES",
            "KODE PRODUK",
            "NAMA PRODUK",
            "ITEM PRODUK",
            "MERK PRODUK",
            "SATUAN",
            "JUMLAH",
            "HARGA JUAL",
            "DISKON",
            "SUBTOTAL",
          ]);
          headerRow.font = { bold: true };

          //FETCH DETAIL
          filterData.forEach((item) => {
            salessheet.addRow([
              moment(String(item.tanggal)).format("DD-MM-YYYY"),
              moment(String(item.tgl_validasi)).format("DD-MM-YYYY"),
              item.no_bukti,
              item.kd_customer,
              item.nama,
              item.kode_outlet,
              item.jenis,
              item.alamat,
              item.pembayaran,
              item.sales,
              item.kode_bahan,
              item.nama_bahan,
              item.ukuran,
              item.merk,
              item.unit,
              item.jumlah,
              item.rp_jual,
              item.diskon,
              item.subtotal,
            ]);
          });

          salessheet.mergeCells("A1:E1");
          salessheet.getColumn(14).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          salessheet.getColumn(15).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          salessheet.getColumn(16).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          salessheet.getColumn(17).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        }

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Penjualan " +
            localStorage.getItem("kode_company") +
            " Periode " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " - " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // LABARUGI DONWLOAD
    async labarugiDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/labarugi", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        console.log(response.data.data);
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("REKAP");
        const titleRow = rekapsheet.addRow([
          "LAPORAN REKAP LABA PENJUALAN - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "KODE JENIS",
          "NAMA JENIS",
          "TOTAL (QTY)",
          "TOTAL JUAL (RP)",
          "TOTAL HPP (RP)",
          "TOTAL LABA (RP)",
        ]);
        headerRow.font = { bold: true };
        response.data.rekap.forEach((item) => {
          rekapsheet.addRow([
            item.kd_jenis,
            item.nm_jenis,
            item.tot_qty,
            item.tot_jual,
            item.tot_hpp,
            item.tot_laba,
          ]);
        });
        // END SHEET KHUSUS REKAP

        const groupJenis = [
          ...new Set(response.data.data.map((item) => item.nm_jenis)),
        ];
        let worksheet = "";
        let filterData = [];
        for (let i = 0; i < groupJenis.length; i++) {
          worksheet = workbook.addWorksheet(groupJenis[i]);

          filterData = response.data.data.filter(function (el) {
            return el.nm_jenis == groupJenis[i];
          });

          // TITLE
          const titleRow = worksheet.addRow([
            "LAPORAN PENJUALAN & LABA RUGI - PERIODE : " +
              moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
              " s/d " +
              moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
          ]);
          titleRow.font = { bold: true };
          worksheet.addRow([""]);

          // HEADER
          const headerRow = worksheet.addRow([
            "TGL NOTA",
            "NO NOTA",
            "CUSTOMER",
            "PEMBAYARAN",
            "KODE",
            "NAMA BARANG",
            "JENIS",
            "JUMLAH",
            "HARGA JUAL",
            "HARGA HPP",
            "TOTAL JUAL",
            "TOTAL HPP",
            "LABA @",
            "TOTAL LABA",
          ]);
          headerRow.font = { bold: true };

          //FETCH DETAIL
          filterData.forEach((item) => {
            worksheet.addRow([
              item.tgl_update,
              item.no_bukti,
              item.nama,
              item.pembayaran,
              item.kode_bahan,
              item.nama_bahan,
              item.nm_jenis,
              item.jumlah,
              item.rp_jual,
              item.harga,
              item.tot_jual,
              item.tot_hpp,
              item.laba,
              item.tot_laba,
            ]);
          });
        }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Laba Penjualan Periode " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " - " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // PIUTANG DONWLOAD
    async piutangDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/piutang", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        console.log(response.data.data);
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("REKAP");
        const titleRow = rekapsheet.addRow([
          "LAPORAN REKAP PIUTANG - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "KODE CUSTOMER",
          "NAMA CUSTOMER",
          "ALAMAT",
          "SALES",
          "PIUTANG",
          "FAKTUR 0-14",
          "FAKTUR 15-30",
          "FAKTUR 31-45",
          "FAKTUR 46-60",
          "FAKTUR 61-90",
          "FAKTUR >91",
          "SISA",
        ]);
        headerRow.font = { bold: true };
        response.data.rekap.forEach((item) => {
          rekapsheet.addRow([
            item.kd_customer,
            item.nama,
            item.alamat,
            item.sales,
            item.rp_outstand,
            item.n1,
            item.n2,
            item.n3,
            item.n4,
            item.n5,
            item.n6,
            item.ndp,
          ]);
        });

        rekapsheet.getColumn(4).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(5).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(6).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(7).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(8).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(9).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(10).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(11).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        rekapsheet.mergeCells("A1:C1");

        rekapsheet.getColumn(1).width = 20;
        rekapsheet.getColumn(2).width = 40;
        rekapsheet.getColumn(3).width = 40; // Adjust the width as needed
        rekapsheet.getColumn(4).width = 19;
        rekapsheet.getColumn(5).width = 19;
        rekapsheet.getColumn(6).width = 19;
        rekapsheet.getColumn(7).width = 19;
        rekapsheet.getColumn(8).width = 19;
        rekapsheet.getColumn(9).width = 19;
        rekapsheet.getColumn(10).width = 19;
        rekapsheet.getColumn(11).width = 19;

        // END SHEET KHUSUS REKAP

        // SHEET KHUSUS NOTA OUTSTAND
        const outstandsheet = workbook.addWorksheet("NOTA OUTSTAND");
        const outstandRow = outstandsheet.addRow([
          "DATA NOTA YANG BELUM LUNAS (OUTSTAND)",
        ]);
        outstandRow.font = { bold: true };
        outstandsheet.addRow([""]);
        const headerRow2 = outstandsheet.addRow([
          "NAMA CUSTOMER",
          "KODE CUSTOMER",
          "KODE OUTLET",
          "TIPE CUSTOMER",
          "SALES",
          "HARI KUNJUNGAN",
          "POLA KUNJUNGAN",
          "NO. TRANSAKSI",
          "TGL. TRANSAKSI",
          "TGL. VALIDASI",
          "TGL. JATUHTEMPO",
          "UMUR",
          "NO. REF",
          "KETERANGAN",
          "NILAI NOTA",
          "PEMBAYARAN",
          "RETUR",
          "POTONGAN",
          "SISA PIUTANG",
          "SALDO PIUTANG",
        ]);
        headerRow2.font = { bold: true };
        response.data.outstand.forEach((item) => {
          outstandsheet.addRow([
            item.nama,
            item.kd_customer,
            item.kode_outlet,
            item.jenis,
            item.sales,
            item.hari_visit,
            item.pola_visit,
            item.no_bukti,
            this.formatDate(item.tanggal),
            this.formatDate(item.tgl_validasi),
            this.formatDate(item.tanggal_jatuhtempo),
            item.umur,
            item.no_ref,
            item.keterangan,
            item.debit,
            item.bayar,
            item.retur,
            item.potongan,
            item.sisa,
            item.total_saldo,
          ]);
        });

        outstandsheet.getColumn(16).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(17).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(18).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(19).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(20).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(21).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        outstandsheet.mergeCells("A1:C1");

        outstandsheet.getColumn(1).width = 20;
        outstandsheet.getColumn(2).width = 20;
        outstandsheet.getColumn(3).width = 30;
        outstandsheet.getColumn(4).width = 30;
        outstandsheet.getColumn(5).width = 50;
        outstandsheet.getColumn(6).width = 40;
        outstandsheet.getColumn(7).width = 19;
        outstandsheet.getColumn(8).width = 19;
        outstandsheet.getColumn(9).width = 19;
        // END SHEET KHUSUS NOTA OUTSTAND

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Piutang " +
            localStorage.getItem("kode_company") +
            " Periode " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " - " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // HUTANG DOWNLOAD
    async hutangDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/hutang", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        console.log(response.data.data);
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("REKAP");
        const titleRow = rekapsheet.addRow([
          "LAPORAN REKAP HUTANG " +
            localStorage.getItem("kode_company") +
            " - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "KODE SUPPLIER",
          "NAMA SUPPLIER",
          "HUTANG",
          "HUTANG 0-14",
          "HUTANG 15-30",
          "HUTANG 31-45",
          "HUTANG 46-60",
          "HUTANG 61-90",
          "HUTANG >91",
          "SISA",
        ]);
        headerRow.font = { bold: true };
        response.data.rekap.forEach((item) => {
          rekapsheet.addRow([
            item.kd_supplier,
            item.nama,
            item.rp_outstand,
            item.n1,
            item.n2,
            item.n3,
            item.n4,
            item.n5,
            item.n6,
            item.ndp,
          ]);
        });

        rekapsheet.getColumn(4).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(5).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(6).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(7).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(8).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(9).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(10).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(11).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        rekapsheet.mergeCells("A1:C1");

        rekapsheet.getColumn(1).width = 20;
        rekapsheet.getColumn(2).width = 40;
        rekapsheet.getColumn(3).width = 40; // Adjust the width as needed
        rekapsheet.getColumn(4).width = 19;
        rekapsheet.getColumn(5).width = 19;
        rekapsheet.getColumn(6).width = 19;
        rekapsheet.getColumn(7).width = 19;
        rekapsheet.getColumn(8).width = 19;
        rekapsheet.getColumn(9).width = 19;
        rekapsheet.getColumn(10).width = 19;
        rekapsheet.getColumn(11).width = 19;

        // END SHEET KHUSUS REKAP

        // SHEET KHUSUS NOTA OUTSTAND
        const outstandsheet = workbook.addWorksheet("HUTANG OUTSTAND");
        const outstandRow = outstandsheet.addRow([
          "DATA HUTANG YANG BELUM LUNAS (OUTSTAND)",
        ]);
        outstandRow.font = { bold: true };
        outstandsheet.addRow([""]);
        const headerRow2 = outstandsheet.addRow([
          "NO. TRANSAKSI",
          "TGL. TRANSAKSI",
          "KODE SUPPLIER",
          "NAMA SUPPLIER",
          "NO. REF",
          "KETERANGAN",
          "NILAI HUTANG",
          "PEMBAYARAN",
          "SISA HUTANG",
        ]);
        headerRow2.font = { bold: true };
        response.data.outstand.forEach((item) => {
          outstandsheet.addRow([
            item.no_bukti,
            this.formatDate(item.tanggal),
            item.kd_customer,
            item.nama,
            item.no_ref,
            item.keterangan,
            item.kredit,
            item.bayar,
            item.sisa,
          ]);
        });

        outstandsheet.getColumn(7).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(8).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        outstandsheet.getColumn(9).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        outstandsheet.mergeCells("A1:C1");

        outstandsheet.getColumn(1).width = 20;
        outstandsheet.getColumn(2).width = 20;
        outstandsheet.getColumn(3).width = 30;
        outstandsheet.getColumn(4).width = 30;
        outstandsheet.getColumn(5).width = 50;
        outstandsheet.getColumn(6).width = 40;
        outstandsheet.getColumn(7).width = 19;
        outstandsheet.getColumn(8).width = 19;
        outstandsheet.getColumn(9).width = 19;
        // END SHEET KHUSUS NOTA OUTSTAND

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Hutang " +
            localStorage.getItem("kode_company") +
            " Periode " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " - " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // DETAIL STOK
    showBarang() {
      this.barang.dialog = true;
    },
    getBarang(value) {
      this.barang.kode_bahan = value.kode_bahan;
      this.barang.barcode = value.barcode;
      this.barang.nama_bahan = value.nama_bahan;
      this.barang.ukuran = value.ukuran;
      this.barang.unit = value.unit;
      this.barang.dialog = false;
      setTimeout(() => {
        this.$refs.inputJumlah.focus();
      }, 100);
    },

    // DETAIL STOK
    async detailStokDownload() {
      try {
        if (this.barang.kode_bahan == "") {
          this.$notify({
            type: "warning",
            text: "Silahkan Pilih Barang Terlebih Dahulu",
          });
          return;
        }
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/detailstok", {
          kode_bahan: this.barang.kode_bahan,
        });
        console.log(response.data.data);
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("Kartu Stok");
        const titleRow = rekapsheet.addRow(["LAPORAN DETAIL STOK"]);
        rekapsheet.addRow(["Kode Barang", this.barang.kode_bahan]);
        rekapsheet.addRow([
          "Nama Barang",
          this.barang.nama_bahan +
            " " +
            this.barang.ukuran +
            " " +
            this.barang.unit,
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "TANGGAL",
          "NO. BUKTI",
          "JENIS TRANS",
          "TERIMA",
          "KELUAR",
          "STOK",
          "NO. REFERENSI",
          "HARGA",
          "RP. TERIMA",
          "RP. KELUAR",
          "SALDO",
        ]);
        headerRow.font = { bold: true };
        response.data.data.forEach((item) => {
          rekapsheet.addRow([
            this.formatDate(item.tanggal),
            item.no_bukti,
            item.jns_trans,
            item.terima_qty,
            item.keluar_qty,
            item.saldo,
            item.no_ref,
            item.harga,
            item.terima_rp,
            item.keluar_rp,
            item.saldo_rp,
          ]);
        });
        // END SHEET KHUSUS REKAP

        // const groupJenis = [
        //   ...new Set(response.data.data.map((item) => item.nm_jenis)),
        // ];
        // let worksheet = "";
        // let filterData = [];
        // for (let i = 0; i < groupJenis.length; i++) {
        //   worksheet = workbook.addWorksheet(groupJenis[i]);

        //   filterData = response.data.data.filter(function (el) {
        //     return el.nm_jenis == groupJenis[i];
        //   });

        //   // TITLE
        //   const titleRow = worksheet.addRow([
        //     "LAPORAN PENJUALAN & LABA RUGI - PERIODE : " +
        //       moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
        //       " s/d " +
        //       moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        //   ]);
        //   titleRow.font = { bold: true };
        //   worksheet.addRow([""]);

        //   // HEADER
        //   const headerRow = worksheet.addRow([
        //     "TGL NOTA",
        //     "NO NOTA",
        //     "CUSTOMER",
        //     "PEMBAYARAN",
        //     "KODE",
        //     "NAMA BARANG",
        //     "JENIS",
        //     "JUMLAH",
        //     "HARGA JUAL",
        //     "HARGA HPP",
        //     "TOTAL JUAL",
        //     "TOTAL HPP",
        //     "LABA @",
        //     "TOTAL LABA",
        //   ]);
        //   headerRow.font = { bold: true };

        //   //FETCH DETAIL
        //   filterData.forEach((item) => {
        //     worksheet.addRow([
        //       item.tgl_update,
        //       item.no_bukti,
        //       item.nama,
        //       item.pembayaran,
        //       item.kode_bahan,
        //       item.nama_bahan,
        //       item.nm_jenis,
        //       item.jumlah,
        //       item.rp_jual,
        //       item.harga,
        //       item.tot_jual,
        //       item.tot_hpp,
        //       item.laba,
        //       item.tot_laba,
        //     ]);
        //   });
        // }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "KARTU STOK " + this.barang.kode_bahan + ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // DETAIL CUSTOMER
    showCustomer() {
      this.customer.dialog = true;
    },
    getCustomer(value) {
      this.customer.kode_customer = value.kode;
      this.customer.nama_customer = value.nama;
      this.customer.dialog = false;
    },

    async detailPiutangDownload() {
      try {
        if (this.customer.kode_customer == "") {
          this.$notify({
            type: "warning",
            text: "Silahkan Pilih Customer Terlebih Dahulu",
          });
          return;
        }
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/detailpiutang", {
          kode_customer: this.customer.kode_customer,
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        console.log(response.data.data);
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("Kartu Piutang");
        const titleRow = rekapsheet.addRow([
          "RINCIAN PIUTANG : " + this.customer.nama_customer,
        ]);
        rekapsheet.addRow([
          "PERIODE : ",
          moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "NO. BUKTI",
          "TANGGAL",
          "NO. REF",
          "KETERANGAN",
          "NO. NOTA",
          "DEBIT",
          "KREDIT",
          "SALDO",
        ]);
        headerRow.font = { bold: true };
        response.data.data.forEach((item) => {
          rekapsheet.addRow([
            item.no_bukti,
            this.formatDate(item.tanggal),
            item.no_ref,
            item.keterangan,
            item.no_invoice,
            item.debit,
            item.kredit,
            item.saldo,
          ]);
        });
        // END SHEET KHUSUS REKAP

        // const groupJenis = [
        //   ...new Set(response.data.data.map((item) => item.nm_jenis)),
        // ];
        // let worksheet = "";
        // let filterData = [];
        // for (let i = 0; i < groupJenis.length; i++) {
        //   worksheet = workbook.addWorksheet(groupJenis[i]);

        //   filterData = response.data.data.filter(function (el) {
        //     return el.nm_jenis == groupJenis[i];
        //   });

        //   // TITLE
        //   const titleRow = worksheet.addRow([
        //     "LAPORAN PENJUALAN & LABA RUGI - PERIODE : " +
        //       moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
        //       " s/d " +
        //       moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        //   ]);
        //   titleRow.font = { bold: true };
        //   worksheet.addRow([""]);

        //   // HEADER
        //   const headerRow = worksheet.addRow([
        //     "TGL NOTA",
        //     "NO NOTA",
        //     "CUSTOMER",
        //     "PEMBAYARAN",
        //     "KODE",
        //     "NAMA BARANG",
        //     "JENIS",
        //     "JUMLAH",
        //     "HARGA JUAL",
        //     "HARGA HPP",
        //     "TOTAL JUAL",
        //     "TOTAL HPP",
        //     "LABA @",
        //     "TOTAL LABA",
        //   ]);
        //   headerRow.font = { bold: true };

        //   //FETCH DETAIL
        //   filterData.forEach((item) => {
        //     worksheet.addRow([
        //       item.tgl_update,
        //       item.no_bukti,
        //       item.nama,
        //       item.pembayaran,
        //       item.kode_bahan,
        //       item.nama_bahan,
        //       item.nm_jenis,
        //       item.jumlah,
        //       item.rp_jual,
        //       item.harga,
        //       item.tot_jual,
        //       item.tot_hpp,
        //       item.laba,
        //       item.tot_laba,
        //     ]);
        //   });
        // }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "KARTU PIUTANG " + this.customer.nama_customer + ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    showSupplier() {
      this.supplier.dialog = true;
    },
    getSupplier(value) {
      this.supplier.kode_supplier = value.kode;
      this.supplier.nama_supplier = value.nama;
      this.supplier.dialog = false;
    },

    async detailHutangDownload() {
      try {
        if (this.supplier.kode_supplier == "") {
          this.$notify({
            type: "warning",
            text: "Silahkan Pilih Supplier Terlebih Dahulu",
          });
          return;
        }
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/detailhutang", {
          kode_supplier: this.supplier.kode_supplier,
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        console.log(response.data.data);
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("Kartu Hutang");
        const titleRow = rekapsheet.addRow([
          "RINCIAN HUTANG : " + this.supplier.nama_supplier,
        ]);
        rekapsheet.addRow([
          "PERIODE : ",
          moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "NO. BUKTI",
          "TANGGAL",
          "NO. REF",
          "KETERANGAN",
          "NO. NOTA",
          "DEBIT",
          "KREDIT",
          "SALDO",
        ]);
        headerRow.font = { bold: true };
        response.data.data.forEach((item) => {
          rekapsheet.addRow([
            item.no_bukti,
            this.formatDate(item.tanggal),
            item.no_ref,
            item.keterangan,
            item.no_invoice,
            item.debit,
            item.kredit,
            item.saldo,
          ]);
        });
        // END SHEET KHUSUS REKAP

        // const groupJenis = [
        //   ...new Set(response.data.data.map((item) => item.nm_jenis)),
        // ];
        // let worksheet = "";
        // let filterData = [];
        // for (let i = 0; i < groupJenis.length; i++) {
        //   worksheet = workbook.addWorksheet(groupJenis[i]);

        //   filterData = response.data.data.filter(function (el) {
        //     return el.nm_jenis == groupJenis[i];
        //   });

        //   // TITLE
        //   const titleRow = worksheet.addRow([
        //     "LAPORAN PENJUALAN & LABA RUGI - PERIODE : " +
        //       moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
        //       " s/d " +
        //       moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        //   ]);
        //   titleRow.font = { bold: true };
        //   worksheet.addRow([""]);

        //   // HEADER
        //   const headerRow = worksheet.addRow([
        //     "TGL NOTA",
        //     "NO NOTA",
        //     "CUSTOMER",
        //     "PEMBAYARAN",
        //     "KODE",
        //     "NAMA BARANG",
        //     "JENIS",
        //     "JUMLAH",
        //     "HARGA JUAL",
        //     "HARGA HPP",
        //     "TOTAL JUAL",
        //     "TOTAL HPP",
        //     "LABA @",
        //     "TOTAL LABA",
        //   ]);
        //   headerRow.font = { bold: true };

        //   //FETCH DETAIL
        //   filterData.forEach((item) => {
        //     worksheet.addRow([
        //       item.tgl_update,
        //       item.no_bukti,
        //       item.nama,
        //       item.pembayaran,
        //       item.kode_bahan,
        //       item.nama_bahan,
        //       item.nm_jenis,
        //       item.jumlah,
        //       item.rp_jual,
        //       item.harga,
        //       item.tot_jual,
        //       item.tot_hpp,
        //       item.laba,
        //       item.tot_laba,
        //     ]);
        //   });
        // }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "KARTU HUTANG " + this.supplier.nama_supplier + ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },
  },
  computed: {
    tanggal1() {
      return this.periode.tanggal1;
    },
    tanggal2() {
      return this.periode.tanggal2;
    },
  },
  watch: {
    tanggal1: function (value1, value2) {
      if (this.periode.tanggal1 > this.periode.tanggal2) {
        alert("Tanggal Awal Melebihi Tanggal Akhir");
        this.periode.tanggal1 = value2;
      }
    },
    tanggal2: function (value1, value2) {
      if (this.periode.tanggal1 > this.periode.tanggal2) {
        alert("Tanggal Awal Melebihi Tanggal Akhir");
        this.periode.tanggal2 = value2;
      }
    },
  },
};
</script>

<style>
</style>