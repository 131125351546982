var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("LOG DATA")])])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('v-text-field',{ref:"inputSearch",attrs:{"name":"name","label":"Pencarian","outlined":"","dense":"","hide-details":"","clearable":"","append-icon":"mdi-magnify"},model:{value:(_vm.data.search),callback:function ($$v) {_vm.$set(_vm.data, "search", $$v)},expression:"data.search"}}),(_vm.jenis != 'keluar')?_c('div',[_c('download-excel',{staticClass:"btn btn-default",attrs:{"fetch":_vm.fetchData,"worksheet":"Log Data","name":"Log Data.xls"}},[_c('v-btn',{attrs:{"color":"success","text":""}},[_vm._v("Excel"),_c('v-icon',[_vm._v("mdi-table-arrow-down")])],1)],1)],1):_vm._e(),_c('div',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.loadData()}}},[_vm._v("REFRESH"),_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.data.headers,"items":_vm.data.items,"loading":_vm.data.loading,"search":_vm.data.search,"dense":""},scopedSlots:_vm._u([{key:"item.initial",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"0.7rem"}},[_vm._v(_vm._s(item.initial.substr(1, 70)))])]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"font-size":"0.7rem"}},[_vm._v(_vm._s(item.updated.substr(1, 70)))])]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user)+" "),_c('v-chip',{attrs:{"x-small":"","label":"","color":"white"}},[_vm._v(_vm._s(item.ip_address))])]}},{key:"item.opsi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info","small":"","rounded":"","text":""},on:{"click":function($event){return _vm.loadDetail(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.rp_jual",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"success","small":"","rounded":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-format-list-checkbox")])],1)],1)]}}],null,true)},[_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" Distributor : Rp. "+_vm._s(item.rp_distributor)+" ")]),_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" Star-Outlet : Rp. "+_vm._s(item.rp_staroutlet)+" ")]),_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" Grosir : Rp. "+_vm._s(item.rp_grosir)+" ")]),_c('div',{staticClass:"d-flex justify-end"},[_vm._v(" Retail : Rp. "+_vm._s(item.rp_retail)+" ")])])]}}],null,true)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1300px","transition":"dialog-transition"},model:{value:(_vm.detail.dialog),callback:function ($$v) {_vm.$set(_vm.detail, "dialog", $$v)},expression:"detail.dialog"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(" ID : "+_vm._s(_vm.data.id)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.detail.headers,"items":_vm.detail.items,"loading":_vm.detail.loading,"search":_vm.detail.search,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.initial",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-top"},[_c('table',{staticClass:"tabledetail"},[_c('tbody',_vm._l((JSON.parse(item.initial)),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[(Array.isArray(value))?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":[
                              { text: 'KODE BARANG', value: 'kode_bahan' },
                              { text: 'JUMLAH', value: 'jumlah' },
                              { text: 'HARGA', value: 'rp_jual' },
                              { text: 'DISKON', value: 'diskon' },
                              { text: 'SUBTOTAL', value: 'subtotal' } ],"items":value,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.jumlah",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.jumlah))+" ")]}},{key:"item.rp_jual",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.rp_jual))+" ")]}},{key:"item.diskon",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.diskon))+" ")]}},{key:"item.subtotal",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber( item.jumlah * item.rp_jual - item.jumlah * item.diskon ))+" ")]}}],null,true)})],1)],1):_c('div',[_vm._v(_vm._s(value))])])])}),0)])])]}},{key:"item.updated",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"d-flex align-top"},[_c('table',{staticClass:"tabledetail"},[_c('tbody',_vm._l((JSON.parse(item.updated)),function(value,key){return _c('tr',{key:key},[_c('td',[_vm._v(_vm._s(key))]),_c('td',[(Array.isArray(value))?_c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":[
                              { text: 'KODE BARANG', value: 'kode_bahan' },
                              { text: 'JUMLAH', value: 'jumlah' },
                              { text: 'HARGA', value: 'rp_jual' },
                              { text: 'DISKON', value: 'diskon' },
                              { text: 'SUBTOTAL', value: 'subtotal' } ],"items":value,"dense":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.jumlah",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.jumlah))+" ")]}},{key:"item.rp_jual",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.rp_jual))+" ")]}},{key:"item.diskon",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.diskon))+" ")]}},{key:"item.subtotal",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber( item.jumlah * item.rp_jual - item.jumlah * item.diskon ))+" ")]}}],null,true)})],1)],1):_c('div',[_vm._v(_vm._s(value))])])])}),0)])])]}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1000px","transition":"dialog-transition"},model:{value:(_vm.details.dialog),callback:function ($$v) {_vm.$set(_vm.details, "dialog", $$v)},expression:"details.dialog"}},[_c('v-card',[_c('v-card-title'),_c('v-card-text',[_c('v-col',{attrs:{"cols":"auto","lg":"6"}},[_c('v-data-table',{attrs:{"headers":[{ text: 'KODE BARANG', value: 'kode_bahan' }],"items":_vm.details.initial}})],1),_c('v-col',{attrs:{"cols":"auto","lg":"6"}},[_c('v-data-table',{attrs:{"headers":[{ text: 'KODE BARANG', value: 'kode_bahan' }],"items":_vm.details.updated}})],1)],1)],1)],1),_c('notifications',{attrs:{"position":"bottom right"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }