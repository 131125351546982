<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>LAPORAN CASHFLOW</h2>
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col cols="auto" lg="6" md="12" sm="12">
        <v-card outlined rounded="lg">
          <v-card-title class="py-3 d-flex justify-space-around">
            <v-menu
              v-model="periode.pickerTanggal1"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Tanggal Awal"
                  :value="formatDate(periode.tanggal1)"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  background-color="light-blue lighten-5"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="periode.tanggal1"
                @input="periode.pickerTanggal1 = false"
              ></v-date-picker>
            </v-menu>
            -
            <v-menu
              v-model="periode.pickerTanggal2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  label="Tanggal Akhir"
                  :value="formatDate(periode.tanggal2)"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  outlined
                  background-color="light-blue lighten-5"
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="periode.tanggal2"
                @input="periode.pickerTanggal2 = false"
              ></v-date-picker>
            </v-menu>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <!-- LAPORAN DAFTAR REKAP -->
            <div class="d-flex justify-space-around align-center">
              <div>Daftar Rekap</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="rekapDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN DAFTAR TRANSAKSI -->
            <div class="d-flex justify-space-around align-center">
              <div>Daftar Transaksi</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="transaksiDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <!-- DATA DETAIL -->
        <v-card class="mt-4" outlined rounded="lg">
          <v-card-text>
            <!-- LAPORAN RINCIAN TRANSAKSI -->
            <div class="d-flex justify-space-around align-center">
              <div>Rincian Transaksi</div>
              <v-spacer></v-spacer>
              <v-text-field
                outlined
                dense
                hide-details
                label="Rekening"
                id="id"
                class="mr-2"
                readonly
                :value="rekening.nama_rekening + ' ' + rekening.kode_rekening"
              ></v-text-field>
              <v-btn
                color="light-blue lighten-4"
                class="mr-4"
                @click="showRekening()"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >
              <v-btn color="success" rounded @click="rinciantransaksiDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
            <v-divider class="my-2"></v-divider>

            <!-- LAPORAN REKENING KORAN -->
            <div class="d-flex justify-space-around align-center">
              <div>Rekening Koran</div>

              <v-spacer></v-spacer>
              <v-text-field
                outlined
                dense
                hide-details
                label="Kas/Bank"
                readonly
                id="id"
                class="mr-2"
                width="200"
                :value="kasbank.nama_bank + ' ' + kasbank.kode_bank"
              ></v-text-field>
              <v-btn
                color="light-blue lighten-4"
                class="mr-4"
                @click="showKasbank()"
                ><v-icon>mdi-magnify</v-icon></v-btn
              >

              <v-btn color="success" rounded @click="rekeningkoranDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <!-- DATA REKAP SALDO BANK -->
        <v-card class="mt-4" outlined rounded="lg">
          <v-card-text>
            <!-- LAPORAN REKAP SALDO BANK -->
            <div class="d-flex justify-space-around align-center">
              <div>Rekap Saldo Bank</div>
              <v-spacer></v-spacer>
              <v-btn color="success" rounded @click="rekapsaldobankDownload()"
                >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="kasbank.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card outlined>
        <bank-view :staPilih="true" @bank="getBank"></bank-view>
      </v-card>
    </v-dialog>

    <!-- KATEGORI - REKENING -->
    <v-dialog
      v-model="rekening.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card outlined>
        <rekening-view
          :staPilih="true"
          ref="loadDataRekening"
          @rekening="getRekening"
        ></rekening-view>
      </v-card>
    </v-dialog>
    <!-- END KATEGORI - REKENING -->

    <notifications position="bottom right"></notifications>
  </v-container>
</template>

<script>
import ExcelJS from "exceljs";
import axios from "axios";
import moment from "moment";
import BankView from "../Master/BankView.vue";
import RekeningView from "../Master/RekeningView.vue";
export default {
  components: {
    BankView,
    RekeningView,
  },
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      loading: false,
      kasbank: {
        dialog: false,
        kode_bank: "",
        nama_bank: "",
      },
      rekening: {
        dialog: false,
        kode_rekening: "",
        nama_rekening: "",
      },
    };
  },
  methods: {
    // REKAP DOWNLOAD
    async rekapDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/cashflow/rekap", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("REKAP");
        const titleRow = rekapsheet.addRow([
          "REKAP CASHFLOW : PERIODE " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);

        response.data.datarekapPerBank.forEach((item) => {
          const rekapRow = rekapsheet.addRow([
            item.blank,
            item.keterangan,
            item.n1,
            item.n2,
            item.n3,
            item.n4,
            item.n5,
            item.n6,
          ]);
          rekapRow.font = { bold: true };
        });

        rekapsheet.addRow([""]);
        const headerRow = rekapsheet.addRow([
          "NO",
          "AKUN",
          "KAS KECIL",
          "KAS BESAR",
          "KAS SHOWROOM",
          "MANDIRI",
          "BNI",
          "BCA",
        ]);
        headerRow.font = { bold: true };
        response.data.dataPerBankMutasi.forEach((item) => {
          rekapsheet.addRow([
            item.ref,
            item.nama_rekening,
            item.n1,
            item.n2,
            item.n3,
            item.n4,
            item.n5,
            item.n6,
          ]);
        });

        rekapsheet.getColumn(3).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(4).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(5).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(6).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(7).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(8).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        rekapsheet.mergeCells("A1:B1");

        rekapsheet.getColumn(1).width = 16;
        rekapsheet.getColumn(2).width = 40;
        rekapsheet.getColumn(3).width = 19; // Adjust the width as needed
        rekapsheet.getColumn(4).width = 19;
        rekapsheet.getColumn(5).width = 19;
        rekapsheet.getColumn(6).width = 19;
        rekapsheet.getColumn(7).width = 19;
        rekapsheet.getColumn(8).width = 19;

        // END SHEET KHUSUS REKAP

        // SHEET KHUSUS REKAP
        const tahunsheet = workbook.addWorksheet(
          moment(String(this.periode.tanggal1)).format("YYYY")
        );
        const titleRow2 = tahunsheet.addRow([
          "REKAP CASHFLOW : PERIODE " +
            moment(String(this.periode.tanggal1)).format("YYYY"),
        ]);
        titleRow2.font = { bold: true };
        tahunsheet.addRow([""]);
        const headerRow2 = tahunsheet.addRow([
          "NO",
          "AKUN",
          "JANUARI",
          "FEBRUARI",
          "MARET",
          "APRIL",
          "MEI",
          "JUNI",
          "JULI",
          "AGUSTUS",
          "SEPTEMBER",
          "OKTOBER",
          "NOVEMBER",
          "DESEMBER",
        ]);
        headerRow2.font = { bold: true };
        response.data.dataPerBulan.forEach((item) => {
          tahunsheet.addRow([
            item.ref,
            item.nama_rekening,
            item.n1,
            item.n2,
            item.n3,
            item.n4,
            item.n5,
            item.n6,
            item.n7,
            item.n8,
            item.n9,
            item.n10,
            item.n11,
            item.n12,
          ]);
        });
        // END SHEET KHUSUS REKAP

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Rekap Cashflow Periode : " +
            this.periode.tanggal1 +
            " - " +
            this.periode.tanggal1 +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    async transaksiDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/cashflow/transaksi", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("Transaksi");
        const titleRow = rekapsheet.addRow([
          "TRANSAKSI : PERIODE " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);

        const headerRow = rekapsheet.addRow([
          "TANGGAL",
          "NO. BUKTI",
          "TRANSAKSI",
          "KETERANGAN",
          "TERIMA",
          "KELUAR",
        ]);
        headerRow.font = { bold: true };
        response.data.data.forEach((item) => {
          rekapsheet.addRow([
            this.formatDate(item.tanggal),
            item.no_bukti,
            item.catatan,
            item.ket,
            item.debet,
            item.kredit,
          ]);
        });

        rekapsheet.getColumn(5).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(6).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        rekapsheet.mergeCells("A1:B1");

        rekapsheet.getColumn(1).width = 20;
        rekapsheet.getColumn(2).width = 20;
        rekapsheet.getColumn(3).width = 50; // Adjust the width as needed
        rekapsheet.getColumn(4).width = 40;
        rekapsheet.getColumn(5).width = 19;
        rekapsheet.getColumn(6).width = 19;

        // END SHEET KHUSUS REKAP

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Transaksi Periode : " +
            this.periode.tanggal1 +
            " - " +
            this.periode.tanggal1 +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    //REKAP SALDO BANK
    async rekapsaldobankDownload() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/cashflow/rekapsaldobank", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        const groupJenis = [
          ...new Set(response.data.data.map((item) => item.nm_bank)),
        ];
        let worksheet = "";
        let filterData = [];
        for (let i = 0; i < groupJenis.length; i++) {
          worksheet = workbook.addWorksheet(groupJenis[i]);

          filterData = response.data.data.filter(function (el) {
            return el.nm_bank == groupJenis[i];
          });
          const titleRow = worksheet.addRow([
            "REKAP TRANSAKSI " +
              groupJenis[i] +
              "- PERIODE : " +
              moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
              " s/d " +
              moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
          ]);
          titleRow.font = { bold: true };
          worksheet.addRow([""]);

          const headerRow = worksheet.addRow([
            "TANGGAL",
            "NO. BUKTI",
            "TRANSAKSI",
            "KETERANGAN",
            "DEBIT",
            "KREDIT",
            "SALDO",
          ]);
          headerRow.font = { bold: true };
          // Add fetched data to the worksheet
          filterData.forEach((item) => {
            worksheet.addRow([
              this.formatDate(item.tanggal),
              item.no_bukti,
              item.catatan,
              item.ket,
              item.debet,
              item.kredit,
              item.saldo,
            ]);
          });

          worksheet.getColumn(5).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          worksheet.getColumn(6).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          worksheet.getColumn(7).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

          worksheet.mergeCells("A1:B1");

          worksheet.getColumn(1).width = 20;
          worksheet.getColumn(2).width = 20;
          worksheet.getColumn(3).width = 50; // Adjust the width as needed
          worksheet.getColumn(4).width = 40;
          worksheet.getColumn(5).width = 19;
          worksheet.getColumn(6).width = 19;
          worksheet.getColumn(7).width = 19;
        }

        // Add data to the worksheet

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Rekap Saldo Bank Periode : " +
            this.periode.tanggal1 +
            " - " +
            this.periode.tanggal1 +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    // DETAIL STOK
    showKasbank() {
      this.kasbank.dialog = true;
    },

    getBank(value) {
      this.kasbank.kode_bank = value.kode_bank;
      this.kasbank.nama_bank = value.nama_bank;

      this.kasbank.dialog = false;
    },

    //REKAP SALDO BANK
    async rekeningkoranDownload() {
      if (!this.kasbank.kode_bank) {
        alert("Silahkan Pilih Bank Terlebih Dahulu");
        return;
      }

      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/cashflow/rekeningkoran", {
          kode_bank: this.kasbank.kode_bank,
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("Rekening Koran");
        const titleRow = rekapsheet.addRow([
          "REKENING KORAN " +
            this.kasbank.nama_bank +
            " : PERIODE " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);

        const headerRow = rekapsheet.addRow([
          "TANGGAL",
          "NO. BUKTI",
          "TRANSAKSI",
          "KETERANGAN",
          "DEBIT",
          "KREDIT",
          "SALDO",
        ]);
        headerRow.font = { bold: true };
        response.data.data.forEach((item) => {
          rekapsheet.addRow([
            this.formatDate(item.tanggal),
            item.no_bukti,
            item.catatan,
            item.ket,
            item.debet,
            item.kredit,
            item.saldo,
          ]);
        });

        rekapsheet.getColumn(5).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(6).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(7).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        rekapsheet.mergeCells("A1:B1");

        rekapsheet.getColumn(1).width = 20;
        rekapsheet.getColumn(2).width = 20;
        rekapsheet.getColumn(3).width = 50; // Adjust the width as needed
        rekapsheet.getColumn(4).width = 40;
        rekapsheet.getColumn(5).width = 19;
        rekapsheet.getColumn(6).width = 19;
        rekapsheet.getColumn(7).width = 19;

        // END SHEET KHUSUS REKAP

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Rekening Koran " +
            this.kasbank.nama_bank +
            " Periode : " +
            this.periode.tanggal1 +
            " - " +
            this.periode.tanggal1 +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },

    showRekening() {
      this.rekening.dialog = true;
    },

    getRekening(value) {
      this.rekening.kode_rekening = value.kode_rekening;
      this.rekening.nama_rekening = value.nama_rekening;

      this.rekening.dialog = false;
    },

    //REKAP SALDO BANK
    async rinciantransaksiDownload() {
      if (!this.rekening.kode_rekening) {
        alert("Silahkan Pilih Rekening/Akun Terlebih Dahulu");
        return;
      }

      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/cashflow/rinciantransaksi", {
          kode_rekening: this.rekening.kode_rekening,
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        // SHEET KHUSUS REKAP
        const rekapsheet = workbook.addWorksheet("Rincian Transaksi");
        const titleRow = rekapsheet.addRow([
          "RINCIAN TRANSAKSI " +
            this.rekening.nama_rekening +
            " (" +
            this.rekening.kode_rekening +
            ")" +
            " : PERIODE " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        rekapsheet.addRow([""]);

        const headerRow = rekapsheet.addRow([
          "TANGGAL",
          "NO. BUKTI",
          "TRANSAKSI",
          "KETERANGAN",
          "DEBIT",
          "KREDIT",
        ]);
        headerRow.font = { bold: true };
        response.data.data.forEach((item) => {
          rekapsheet.addRow([
            this.formatDate(item.tanggal),
            item.no_bukti,
            item.catatan,
            item.ket,
            item.debet,
            item.kredit,
          ]);
        });

        rekapsheet.getColumn(5).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        rekapsheet.getColumn(6).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        rekapsheet.mergeCells("A1:F1");

        rekapsheet.getColumn(1).width = 20;
        rekapsheet.getColumn(2).width = 20;
        rekapsheet.getColumn(3).width = 50; // Adjust the width as needed
        rekapsheet.getColumn(4).width = 40;
        rekapsheet.getColumn(5).width = 19;
        rekapsheet.getColumn(6).width = 19;
        // END SHEET KHUSUS REKAP

        const groupAkun = [
          ...new Set(response.data.data.map((item) => item.nama_akun)),
        ];
        let sheetAkun = "";
        let filterAkun = [];
        for (let i = 0; i < groupAkun.length; i++) {
          sheetAkun = workbook.addWorksheet(groupAkun[i]);

          filterAkun = response.data.data.filter(function (el) {
            return el.nama_akun == groupAkun[i];
          });

          // TITLE
          const titleAkun = sheetAkun.addRow([
            "RINCIAN TRANSAKSI " +
              this.rekening.nama_rekening +
              "," +
              response.data.data[0].nama_akun +
              " (" +
              response.data.data[0].akun_debet +
              ")" +
              " : PERIODE " +
              moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
              " s/d " +
              moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
          ]);
          titleAkun.font = { bold: true };
          sheetAkun.addRow([""]);

          // HEADER
          const headerAkun = sheetAkun.addRow([
            "TANGGAL",
            "NO. BUKTI",
            "TRANSAKSI",
            "KETERANGAN",
            "DEBIT",
            "KREDIT",
          ]);
          headerAkun.font = { bold: true };

          //FETCH DETAIL
          filterAkun.forEach((item) => {
            sheetAkun.addRow([
              this.formatDate(item.tanggal),
              item.no_bukti,
              item.catatan,
              item.ket,
              item.debet,
              item.kredit,
            ]);
          });

          sheetAkun.getColumn(5).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
          sheetAkun.getColumn(6).numFmt =
            '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

          sheetAkun.mergeCells("A1:F1");

          sheetAkun.getColumn(1).width = 20;
          sheetAkun.getColumn(2).width = 20;
          sheetAkun.getColumn(3).width = 50; // Adjust the width as needed
          sheetAkun.getColumn(4).width = 40;
          sheetAkun.getColumn(5).width = 19;
          sheetAkun.getColumn(6).width = 19;
        }

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Rincian Transaksi " +
            this.rekening.nama_rekening +
            " Periode : " +
            this.periode.tanggal1 +
            " - " +
            this.periode.tanggal1 +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },
  },
  computed: {
    tanggal1() {
      return this.periode.tanggal1;
    },
    tanggal2() {
      return this.periode.tanggal2;
    },
  },
  watch: {
    tanggal1: function (value1, value2) {
      if (this.periode.tanggal1 > this.periode.tanggal2) {
        alert("Tanggal Awal Melebihi Tanggal Akhir");
        this.periode.tanggal1 = value2;
      }
    },
    tanggal2: function (value1, value2) {
      if (this.periode.tanggal1 > this.periode.tanggal2) {
        alert("Tanggal Awal Melebihi Tanggal Akhir");
        this.periode.tanggal2 = value2;
      }
    },
  },
};
</script>

<style>
</style>