<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-around">
        <h2>LIST BUKU KAS</h2>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="primary" text @click="loadData()"
            >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-1">
      <!-- TOTAL SALDO AWAL -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-text-box</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalSaldoAwal) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Saldo Awal</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PEMASUKAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-note-plus</v-icon></v-btn
            >
            <div class="text-h5">+ {{ formatNumber(totalPemasukan) }}</div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Pemasukan</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PENGELUARAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-cart-arrow-up</v-icon></v-btn
            >
            <div class="text-h5">- {{ formatNumber(totalPengeluaran) }}</div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Pengeluaran</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL SALDO AKHIR -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-note-search</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalSaldoAkhir) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Saldo Akhir</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div>
              <v-text-field
                label="Pencarian"
                outlined
                dense
                hide-details
                v-model="data.search"
                clearable
                append-icon="mdi-magnify"
                class="mr-2"
              ></v-text-field>
            </div>
            <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Awal"
                    :value="formatDate(periode.tanggal1)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal1"
                  @change="loadData()"
                  @input="periode.pickerTanggal1 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Akhir"
                    :value="formatDate(periode.tanggal2)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal2"
                  @change="loadData()"
                  @input="periode.pickerTanggal2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div>
              <v-select
                :items="bank.items"
                v-model="bank.value"
                item-text="nm_bank"
                item-value="kode"
                label="Kas/Bank"
                dense
                outlined
                rounded
                hide-details
                @change="loadFilter()"
                class="mr-2"
              ></v-select>
            </div>
            <div>
              <v-btn-toggle
                rounded
                v-model="filter.jenis"
                color="info"
                mandatory
                class="mr-2"
              >
                <v-btn value="semua" @click="setFilterSemua()"> Semua</v-btn>
              </v-btn-toggle>
            </div>

            <!-- <div>
              <v-btn-toggle
                rounded
                v-model="filter.jenis"
                color="info"
                mandatory
                class="mr-2"
              >
                <v-btn small value="semua"> Semua ({{ countTagihan }}) </v-btn>
                <v-btn small value="lunas"
                  ><v-icon small color="success"
                    >mdi-check-circle-outline</v-icon
                  >
                  Lunas ({{ countLunas }})
                </v-btn>
                <v-btn small value="belum"
                  ><v-icon small color="warning"
                    >mdi-alert-circle-outline</v-icon
                  >
                  Belum Lunas ({{ countBelumLunas }})
                </v-btn>
              </v-btn-toggle>
            </div> -->
            <v-spacer></v-spacer>
            <!-- <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Return Penjualan"
                name="Data Return Penjualan.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div> -->
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="filterData"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.nama`]="{ item }">
                  <div class="d-flex justify-space-around">
                    <strong>{{ item.nama }}</strong>
                    <v-spacer></v-spacer>
                    <v-icon color="success" v-if="item.rp_outstand <= 0" small
                      >mdi-check-circle-outline</v-icon
                    >
                  </div>
                </template>
                <template v-slot:[`item.opsi`]="{ item }">
                  <div v-if="item.no_bukti != 'SALDO AWAL'">
                    <v-btn
                      color="info"
                      text
                      x-small
                      rounded
                      outlined
                      @click="showDetail(item.no_bukti)"
                      ><v-icon small>mdi-pen</v-icon></v-btn
                    >
                  </div>
                </template>
                <template v-slot:[`item.tanggal`]="{ item }">
                  {{ formatDate(item.tanggal) }}
                </template>
                <template v-slot:[`item.rp_outstand`]="{ item }">
                  <strong> {{ formatNumber(item.rp_outstand) }}</strong>
                </template>
                <template v-slot:[`item.debet`]="{ item }">
                  {{ formatNumber(item.debet) }}
                </template>
                <template v-slot:[`item.kredit`]="{ item }">
                  {{ formatNumber(item.kredit) }}
                </template>
                <template v-slot:[`item.saldo`]="{ item }">
                  {{ formatNumber(item.saldo) }}
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="detail.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card outlined class="pb-4">
        <v-card-title class="py-2 d-flex justify-space-between">
          <div>
            <strong>{{ data.noBukti }}</strong>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn color="error" @click="konfirmBatal()"
              ><v-icon class="mr-2">mdi-close-thick</v-icon>Batalkan</v-btn
            >
          </div>
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-alert dense outlined type="error">
            Pembatalan akan mengubah semua nilai atas no.transaksi menjadi 0.
            <br />
            Silahkan input kembali transaksi kasbank melalui menu "Input
            Transaksi Kas" jika pembatalan ini merupakan koreksi atas kesalahan
            penginputan. <br />
            Histori pembatalan akan tercatat di menu "Log Data".
          </v-alert>
          <!-- <v-card outlined>
            <v-data-table
              :headers="detail.headers"
              :items="detail.items"
              :loading="detail.loading"
              :search="detail.search"
              dense
            >
              <template v-slot:[`item.tanggal`]="{ item }">
                {{ formatDate(item.tanggal) }}
              </template>
              <template v-slot:[`item.debit`]="{ item }">
                {{ formatNumber(item.debit) }}
              </template>
              <template v-slot:[`item.kredit`]="{ item }">
                {{ formatNumber(item.kredit) }}
              </template>

              <template v-slot:[`item.saldo`]="{ item }">
                {{ formatNumber(item.saldo) }}
              </template>
            </v-data-table>
          </v-card> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="konfirm.dialog"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="pb-4">
        <v-card-title> Konfirmasi Pembatalan </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex justify-space-around mt-2">
          <v-btn color="error" @click="simpanBatal()">Ya</v-btn>
          <v-btn @click="konfirm.dialog = false">Tidak</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["staPilih"],
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      data: {
        headers: [
          { text: "KAS/BANK", value: "nama_kasbank", divider: true },
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "CATATAN", value: "catatan", divider: true },
          { text: "KETERANGAN", value: "ket", divider: true },
          { text: "MASUK", value: "debet", divider: true, align: "right" },
          { text: "KELUAR", value: "kredit", divider: true, align: "right" },
          { text: "SALDO", value: "saldo", divider: true, align: "right" },
          { text: "OPSI", value: "opsi", divider: true },
        ],
        items: [],
        loading: false,
        search: "",
        noBukti: "",
        index: "",
      },
      filter: {
        jenis: "",
      },
      detail: {
        dialog: false,
        headers: [
          // { text: "TANGGAL", value: "tanggal", divider: true },
          // { text: "NO. BUKTI", value: "no_bukti", divider: true },
          // { text: "NO. REF", value: "no_ref", divider: true },
          // { text: "KET", value: "keterangan", divider: true },
          // { text: "DEBIT", value: "debit", divider: true, align: "right" },
          // { text: "KREDIT", value: "kredit", divider: true, align: "right" },
          // { text: "SALDO", value: "saldo", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
      },
      konfirm: {
        dialog: false,
      },
      bank: {
        items: [],
        loading: false,
        search: "",
        value: "",
      },
    };
  },
  mounted() {
    this.loadData();
    this.loadFilterBank();
  },
  methods: {
    async simpanBatal() {
      await axios
        .post("kasbank/cancel", {
          no_bukti: this.data.noBukti,
          user: this.$store.state.auth.user.name.toUpperCase(),
        })
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.message, "error");
            return;
          }
          swal("Sukses", res.data.message, "success");
          this.konfirm.dialog = false;
          this.detail.dialog = false;
          this.loadData();
        })
        .catch((err) => {
          swal("Gagal", "Ada Kesalahan Data " + err, "error");
        });
    },
    konfirmBatal() {
      this.konfirm.dialog = true;
    },
    showDetail(noBukti) {
      this.data.noBukti = noBukti;
      this.detail.dialog = true;
      this.loadDetail();
    },
    async loadDetail() {
      this.detail.items = [];
      this.detail.loading = true;
      this.detail.dialog = true;
      await axios
        .post("kasbank/detail", {
          no_bukti: this.data.noBukti,
        })
        .then((res) => {
          this.detail.items = res.data.data;
        });
      this.detail.loading = false;
    },
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("kasbank", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
          kode_bank: this.bank.value,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },
    loadFilter() {
      this.loadData();
    },
    async loadFilterBank() {
      this.bank.items = [];
      this.bank.loading = true;
      await axios
        .post("master/bank")
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.bank.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.bank.loading = false;
    },
    setFilterSemua() {
      this.bank.value = "";
      this.loadData();
    },
    async fetchData() {
      const res = await axios.post("master/customer");
      return res.data.data;
    },
  },
  computed: {
    totalSaldoAwal() {
      let a = 0;
      this.filterData.forEach((element) => {
        if (element.no_bukti == "SALDO AWAL") {
          a = element.debet - element.kredit;
        }
      });
      return a;
    },
    totalPemasukan() {
      let a = 0;
      this.filterData.forEach((element) => {
        if (element.no_bukti != "SALDO AWAL") {
          a = a + element.debet;
        }
      });
      return a;
    },
    totalPengeluaran() {
      let a = 0;
      this.filterData.forEach((element) => {
        if (element.no_bukti != "SALDO AWAL") {
          a = a + element.kredit;
        }
      });
      return a;
    },
    totalSaldoAkhir() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + (element.debet - element.kredit);
      });
      return a;
    },
    countBelumLunas() {
      let a = 0;
      this.data.items.forEach((el) => {
        if (el.rp_outstand > 0) {
          a++;
        }
      });
      return a;
    },
    filterData() {
      let data = "";
      if (this.bank.value == "") {
        data = this.data.items;
      } else {
        data = this.data.items.filter((el) => el.akun_bank == this.bank.value);
      }

      return data;
    },
  },
};
</script>

<style></style>
