<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>PROFIL</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2 d-flex justify-center">
      <v-col cols="auto" lg="8">
        <v-card>
          <v-card-title> Ubah Password </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form v-model="isValid">
              <!-- <v-text-field
                v-model="passwordLama"
                :rules="rules.passwordLama"
                label="Password Lama"
                placeholder="Masukkan Password Lama"
                dense
                outlined
                autocomplete="off"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field> -->
              <v-text-field
                v-model="passwordBaru1"
                ref="inputPassword1"
                v-on:keyup.13="$refs.inputPassword2.focus()"
                :rules="rules.passwordBaru1"
                label="Password Baru"
                placeholder="Masukkan Password Baru"
                dense
                outlined
                autocomplete="off"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <v-text-field
                ref="inputPassword2"
                v-on:keyup.13="$refs.buttonUpdate.$el.focus()"
                v-model="passwordBaru2"
                :rules="rules.passwordBaru2"
                label="Ulangi Password Baru"
                placeholder="Masukkan Ulang Password Baru"
                dense
                outlined
                autocomplete="off"
                prepend-inner-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
              <div class="d-flex justify-end">
                <v-btn color="success" :disabled="!isValid" @click="update()" ref="buttonUpdate"
                  ><v-icon class="mr-2">mdi-floppy</v-icon> SIMPAN</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      isValid: false,
      showPassword: false,
      passwordLama: "",
      passwordBaru1: "",
      passwordBaru2: "",
      user: this.$store.state.auth.user.name,
      rules: {
        // passwordLama: [(v) => v != "" || "Password Lama Harus Diisi"],
        passwordBaru1: [
          (v) => v != "" || "Password Harus Diisi",
          (v) => v.length >= 8 || "Password Harus Lebih Dari 8 Karakter",
        ],
        passwordBaru2: [
          (v) => v != "" || "Konfirmasi Password Harus Diisi",
          (v) => v == this.passwordBaru1 || "Password Tidak Sama",
        ],
      },
    };
  },
  mounted() {
    this.$refs.inputPassword1.focus();
  },
  methods: {
    async update() {
      await axios
        .post("auth/updatepassword", {
          name: this.user,
          passwordBaru1: this.passwordBaru1,
          passwordBaru2: this.passwordBaru2,
        })
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.message, "error");
            return;
          }
          swal("Success", res.data.message, "success");
          this.resetForm();
        });
    },
    resetForm() {
      this.passwordBaru1 = "";
      this.passwordBaru2 = "";
    },
  },
  computed: {},
};
</script>

<style>
</style>