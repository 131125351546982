<template>
  <v-container>
    <v-row>
      <v-col>
        <v-btn color="success" class="mr-4" @click="loadData()">PULL</v-btn>
        <v-btn color="success" @click="push()">PUSH</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="data.headers"
              :items="data.items"
              :loading="data.loading"
              :search="data.search"
              dense
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      data: {
        headers: [
          { text: "id", value: "id" },
          { text: "client_order_id", value: "client_order_id" },
          { text: "ship_name", value: "ship_name" },
          { text: "ship_phone_no", value: "ship_phone_no" },
          { text: "ship_email", value: "ship_email" },
          { text: "ship_address", value: "ship_address" },
          { text: "ship_province", value: "ship_province" },
          { text: "ship_province_code", value: "ship_province_code" },
          { text: "ship_city", value: "ship_city" },
          { text: "ship_city_code", value: "ship_city_code" },
          { text: "ship_subdistrict", value: "ship_subdistrict" },
          { text: "ship_subdistrict_code", value: "ship_subdistrict_code" },
          { text: "ship_village", value: "ship_village" },
          { text: "ship_village_code", value: "ship_village_code" },
          { text: "date_order", value: "date_order" },
          { text: "product_code", value: "product_code" },
          { text: "cek", value: "cek" },
        ],
        items: [],
        loading: false,
        search: "",
      },
      request: {}
    };
  },
  methods: {
    async loadData() {
      await axios.post("test/getorder").then((res) => {
        this.data.items = res.data.data;
      });
    },
    async push() {
      const config = {
        headers: {
          "X-API-KEY": "MilxGRcRoRvLZP0Q",
        },
      };
      for (let i = 0; i < 100; i++) {
        this.request = {
          client_order_id: this.data.items[i].client_order_id,
          client_order_red: this.data.items[i].client_order_red,
          client_order_id_ref: this.data.items[i].client_order_id_ref,
          channel_name: this.data.items[i].channel_name,
          origin: this.data.items[i].origin,
          customer_id: this.data.items[i].customer_id,
          customer_name: this.data.items[i].customer_name,
          ship_name: this.data.items[i].ship_name,
          ship_phone_no: this.data.items[i].ship_phone_no,
          ship_email: this.data.items[i].ship_email,
          ship_address: this.data.items[i].ship_address,
          ship_province: this.data.items[i].ship_province,
          ship_province_code: this.data.items[i].ship_province_code,
          ship_city: this.data.items[i].ship_city,
          ship_city_code: this.data.items[i].ship_city_code,
          ship_subdistrict: this.data.items[i].ship_subdistrict,
          ship_subdistrict_code: this.data.items[i].ship_subdistrict_code,
          ship_village: this.data.items[i].ship_village,
          ship_village_code: this.data.items[i].ship_village_code,
          ship_zip_code: this.data.items[i].ship_zip_code,
          date_order: this.data.items[i].date_order,
          shipping_provider: this.data.items[i].shipping_provider,
          shipping_provider_type: this.data.items[i].shipping_provider_type,
          upbjj: this.data.items[i].upbjj,
          upbjj_address: this.data.items[i].upbjj_address,
          warehouse_id: this.data.items[i].warehouse_id,
          company_id: this.data.items[i].company_id,
          note: this.data.items[i].note,
          picking_policy: this.data.items[i].picking_policy,
          operating_unit_id: this.data.items[i].operating_unit_id,
          lines: [
            {
              product_id: this.data.items[i].product_id,
              product_code: this.data.items[i].product_code,
              product_name:this.data.items[i].product_name,
              price_unit: this.data.items[i].price_unit,
              product_uom_qty: this.data.items[i].product_uom_qty,
            },
          ],
        };
        await axios
          .post("http://54.179.136.7/api/v1/order/add-do", this.request, config)
          .then((response) => {
            console.log("Response:", response);
            this.data.items[i].cek = "OK"
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error);
          });
      }
    },
  },
};
</script>

<style>
</style>