<template>
  <v-container>
    <v-row class="d-flex justify-space-between">
      <v-col cols="auto">
        <h2>DASHBOARD</h2>
      </v-col>
      <v-spacer></v-spacer>
      
      <v-col cols="auto">
        Last Refreshed :  {{ formatDateTime(currentDate = new Date()) }}
        <v-btn color="primary" rounded @click="loadAll()"
          ><v-icon>mdi-refresh</v-icon> Refresh</v-btn
        >
      </v-col>
    </v-row>
    <v-divider class="my-2"></v-divider>

    <v-row class="d-flex justify-space-between mt-2">
      <v-col cols="auto" class="pb-0">
        <h3>- Transaksi</h3>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" class="pb-0">
        <v-menu
          v-model="periode.pickerTanggal"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatMonthYear(periode.tanggal)"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              outlined
              hide-details
              background-color="white"
              rounded
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="periode.tanggal"
            type="month"
            @change="loadAll()"
            @input="periode.pickerTanggal = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <!-- TOTAL SALES MONTHLY -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-tag-multiple</v-icon></v-btn
            >
            <div class="text-h4">
              {{ formatNumber(totalsales.value) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-subtitle-1">Total Penjualan</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PURCHASE MONTHLY -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-cart-arrow-down</v-icon></v-btn
            >
            <div class="text-h4">
              {{ formatNumber(totalpurchase.value) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-subtitle-1">Total Pembelian</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- TOP CUSTOMER & BARANG -->
    <v-row class="mt-2">
      <!-- TOP 5 CUSTOMER -->
      <v-col cols="auto" lg="6" sm="6" xs="12">
        <v-card elevation="0" rounded="lg">
          <v-card-title class="d-flex justify-space-around py-2">
            <v-icon color="primary" class="mr-2">mdi-store</v-icon>
            TOP 5 CUSTOMER
            <v-spacer></v-spacer>
            <!-- <v-menu
              v-model="periode.pickerTanggal"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatMonthYear(periode.tanggal)"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="periode.tanggal"
                type="month"
                @change="loadTopCustomer()"
                @input="periode.pickerTanggal = false"
              ></v-date-picker>
            </v-menu> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="topcustomer.headers"
              :items="topcustomer.items"
              :loading="topcustomer.loading"
              :search="topcustomer.search"
              hide-default-footer
              dense
            >
              <template v-slot:[`item.sum_rp`]="{ item }">
                {{ formatNumber(item.sum_rp) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- TOP 5 CUSTOMER -->

      <!-- TOP 5 BARANG -->
      <v-col cols="auto" lg="6" sm="6" xs="12">
        <v-card elevation="0" rounded="lg">
          <v-card-title class="d-flex justify-space-around py-2">
            <v-icon color="primary" class="mr-2">mdi-archive</v-icon>TOP 5
            BARANG
            <v-spacer></v-spacer>
            <!-- <v-menu
              v-model="topbarang.pickerTanggal"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="formatMonthYear(topbarang.tanggal)"
                  append-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  dense
                  solo
                  hide-details
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="topbarang.tanggal"
                type="month"
                @change="loadTopBarang()"
                @input="topbarang.pickerTanggal = false"
              ></v-date-picker>
            </v-menu> -->
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="topbarang.headers"
              :items="topbarang.items"
              :loading="topbarang.loading"
              :search="topbarang.search"
              hide-default-footer
              dense
            >
              <template v-slot:[`item.sum_rp`]="{ item }">
                {{ formatNumber(item.sum_rp) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- TOP 5 BARANG -->
    </v-row>

    <!-- SUMMARY DATABASE -->
    <v-row class="d-flex justify-space-between mt-4">
      <v-col cols="auto" class="pb-0">
        <h3>- Database</h3>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <!-- TOTAL BARANG/PRODUK -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-archive</v-icon></v-btn
            >
            <div class="text-h4">
              {{ formatNumber(database.barang) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-subtitle-1">Barang/Produk</div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- TOTAL CUSTOMER -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-store</v-icon></v-btn
            >
            <div class="text-h4">
              {{ formatNumber(database.customer) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-subtitle-1">Customer</div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- TOTAL SUPPLIER -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-shopping</v-icon></v-btn
            >
            <div class="text-h4">
              {{ formatNumber(database.supplier) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="text-subtitle-1">Supplier</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- TOP CUSTOMER & BARANG -->

    <!-- <v-row class="mt-2">
      <v-col cols="auto" lg="3" v-for="(item, index) in header1" :key="index">
        <v-card outlined rounded="xl" class="pa-3">
          <v-card-title class="d-flex justify-space-between">
            <v-icon x-large color="purple">{{ item.icon }}</v-icon>
            <v-spacer></v-spacer>
            <v-chip color="primary" dark small>2023</v-chip>
          </v-card-title>
          <v-card-text>
            <div class="text-h2">{{ item.value }}</div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <h3>{{ item.title }}</h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      periode: {
        tanggal: new Date().toISOString().substr(0, 7),
        pickerTanggal: false,
      },
      // TOTAL SALES
      totalsales: {
        value: 0,
        loading: false,
      },
      // END TOTAL SALES

      // TOTAL PURCHASE
      totalpurchase: {
        value: 0,
        loading: false,
      },
      // END TOTAL PURCHASE

      // CUSTOMER
      topcustomer: {
        // tanggal: new Date().toISOString().substr(0, 7),
        // pickerTanggal: false,
        headers: [
          { text: "NO", value: "nomor", width: "5%" },
          { text: "NAMA CUSTOMER", value: "nama" },
          { text: "TOTAL (RP)", value: "sum_rp", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
      },
      // END CUSTOMER

      // BARANG
      topbarang: {
        // tanggal: new Date().toISOString().substr(0, 7),
        // pickerTanggal: false,
        headers: [
          { text: "NO", value: "nomor", width: "5%" },
          { text: "NAMA BARANG", value: "nama_bahan" },
          { text: "UNIT", value: "unit" },
          { text: "TOTAL (QTY)", value: "count_qty", align: "right" },
          { text: "TOTAL (RP)", value: "sum_rp", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
      },
      // END BARANG

      // DATABASE
      database: {
        barang: 0,
        customer: 0,
        supplier: 0,
        loading: false,
      },
      // END DATABASE

      header1: [
        {
          icon: "mdi-scale-balance",
          value: "1000",
          title: "Stok Awal",
        },
        {
          icon: "mdi-inbox-arrow-down-outline",
          value: "2000",
          title: "Penerimaan",
        },
        {
          icon: "mdi-percent",
          value: "3000",
          title: "Penjualan",
        },
        {
          icon: "mdi-archive",
          value: "4000",
          title: "Stok",
        },
      ],
    };
  },
  mounted() {
    this.loadAll();
  },
  methods: {
    loadAll() {
      this.loadTotalSales();
      this.loadTotalPurchase();
      this.loadTopCustomer();
      this.loadTopBarang();
      this.loadDatabase();
    },
    async loadTotalSales() {
      this.totalsales.loading = true;
      this.totalsales.value = 0;
      await axios
        .post("dashboard/salesmonth", {
          tahun: this.periode.tanggal.substring(0, 4),
          bulan: this.periode.tanggal.substring(5),
        })
        .then((res) => {
          this.totalsales.value = res.data.data.sum_rp;
        });
      this.totalsales.loading = false;
    },
    async loadTotalPurchase() {
      this.totalpurchase.loading = true;
      this.totalpurchase.value = 0;
      await axios
        .post("dashboard/purchasemonth", {
          tahun: this.periode.tanggal.substring(0, 4),
          bulan: this.periode.tanggal.substring(5),
        })
        .then((res) => {
          this.totalpurchase.value = res.data.data.sum_rp;
        });
      this.totalpurchase.loading = false;
    },
    async loadTopCustomer() {
      this.topcustomer.loading = true;
      this.topcustomer.items = [];
      await axios
        .post("dashboard/topcustomer", {
          tahun: this.periode.tanggal.substring(0, 4),
          bulan: this.periode.tanggal.substring(5),
        })
        .then((res) => {
          this.topcustomer.items = res.data.data;
        });
      this.topcustomer.loading = false;
    },
    async loadTopBarang() {
      this.topbarang.loading = true;
      this.topbarang.items = [];
      await axios
        .post("dashboard/topbarang", {
          tahun: this.periode.tanggal.substring(0, 4),
          bulan: this.periode.tanggal.substring(5),
        })
        .then((res) => {
          this.topbarang.items = res.data.data;
        });
      this.topbarang.loading = false;
    },
    async loadDatabase() {
      this.database.loading = true;
      this.database.barang = 0;
      this.database.customer = 0;
      this.database.supplier = 0;
      await axios
        .post("dashboard/database", {
          tahun: this.periode.tanggal.substring(0, 4),
          bulan: this.periode.tanggal.substring(5),
        })
        .then((res) => {
          this.database.barang = res.data.barang.count;
          this.database.customer = res.data.customer.count;
          this.database.supplier = res.data.supplier.count;
        });
      this.database.loading = false;
    },
  },
};
</script>

<style>
</style>