<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-around">
        <h2>LAPORAN STOK</h2>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="primary" text @click="loadData()"
            >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col class="d-flex justify-start">
        <div class="mr-2">
          <v-menu
            v-model="periode.pickerTanggal1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tanggal Awal"
                :value="formatDate(periode.tanggal1)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="periode.tanggal1"
              @change="loadData()"
              @input="periode.pickerTanggal1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="mr-2">
          <v-menu
            v-model="periode.pickerTanggal2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tanggal Akhir"
                :value="formatDate(periode.tanggal2)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="periode.tanggal2"
              @change="loadData()"
              @input="periode.pickerTanggal2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <!-- TOTAL NOTA TAGIHAN -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-text-box</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalSaldoAwalQty) }}
              <v-divider></v-divider>
              <div style="font-size: 0.9rem; color: grey">
                {{ formatNumber(totalSaldoAwalRp) }}
              </div>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Saldo Awal</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PELUNASAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-note-plus</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalTerimaQty) }}
              <v-divider></v-divider>
              <div style="font-size: 0.9rem; color: grey">
                {{ formatNumber(totalTerimaRp) }}
              </div>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Terima</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PELUNASAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-cart-arrow-up</v-icon></v-btn
            >
            <div class="text-h5">
              - {{ formatNumber(totalKeluarQty) }}
              <v-divider></v-divider>
              <div style="font-size: 0.9rem; color: grey">
                - {{ formatNumber(totalKeluarRp) }}
              </div>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Keluar</div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="auto" lg="3">
        <!-- TOTAL PELUNASAN -->
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-note-search</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalSaldoAkhirQty) }}
              <v-divider></v-divider>
              <div style="font-size: 0.9rem; color: grey">
                {{ formatNumber(totalSaldoAkhirRp) }}
              </div>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Saldo Akhir</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <!-- <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Awal"
                    :value="formatDate(periode.tanggal1)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal1"
                  @change="loadData()"
                  @input="periode.pickerTanggal1 = false"
                ></v-date-picker>
              </v-menu>
            </div>
            <div class="mr-2">
              <v-menu
                v-model="periode.pickerTanggal2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    label="Tanggal Akhir"
                    :value="formatDate(periode.tanggal2)"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="periode.tanggal2"
                  @change="loadData()"
                  @input="periode.pickerTanggal2 = false"
                ></v-date-picker>
              </v-menu>
            </div> -->
            <div>
              <v-text-field
                label="Pencarian"
                outlined
                dense
                hide-details
                v-model="data.search"
                clearable
                append-icon="mdi-magnify"
                class="mr-2"
              ></v-text-field>
            </div>

            <div>
              <v-select
                :items="merk.items"
                v-model="merk.value"
                item-text="merk"
                label="Merk"
                dense
                outlined
                rounded
                hide-details
                @change="loadFilter()"
                class="mr-2"
              ></v-select>
            </div>
            <div>
              <v-btn-toggle
                rounded
                v-model="filter.jenis"
                color="info"
                mandatory
                class="mr-2"
              >
                <v-btn value="semua" small @click="setFilterSemua()"> Semua</v-btn>
              </v-btn-toggle>
            </div>
            <v-spacer></v-spacer>
            <!-- <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Return Penjualan"
                name="Data Return Penjualan.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div> -->
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="filterData"
                :loading="data.loading"
                :search="data.search"
              >
                <template v-slot:[`item.opsi`]="{ item }">
                  <v-btn
                    @click="loadDetail(item.kode_bahan,item.nama_bahan)"
                    color="primary"
                    text
                    ><v-icon>mdi-format-list-bulleted</v-icon></v-btn
                  >
                </template>
                <template v-slot:[`item.jenis`]="{ item }">
                  {{ item.kd_jenis }} {{ item.nm_jenis }}
                </template>
                <template v-slot:[`item.nama_bahan`]="{ item }">
                  <div>{{ item.kode_bahan }}</div>
                  <div>
                    <strong>{{ item.nama_bahan }}</strong>
                    <v-chip outlined small>{{ item.unit }}</v-chip>
                  </div>
                </template>
                <template v-slot:[`item.saldo_awal_qty`]="{ item }">
                  <div style="font-weight: 700">
                    {{ formatNumber(item.saldo_awal_qty) }}
                  </div>
                  <v-divider></v-divider>
                  <div style="font-size: 0.7rem; color: grey">
                    {{ formatNumber(item.saldo_awal_rp) }}
                  </div>
                </template>

                <template v-slot:[`item.terima_qty`]="{ item }">
                  <div style="font-weight: 700">
                    {{ formatNumber(item.terima_qty) }}
                  </div>
                  <v-divider></v-divider>
                  <div style="font-size: 0.7rem; color: grey">
                    {{ formatNumber(item.terima_rp) }}
                  </div>
                </template>

                <template v-slot:[`item.keluar_qty`]="{ item }">
                  <div style="font-weight: 700">
                    {{ formatNumber(item.keluar_qty) }}
                  </div>
                  <v-divider></v-divider>
                  <div style="font-size: 0.7rem; color: grey">
                    {{ formatNumber(item.keluar_rp) }}
                  </div>
                </template>

                <template v-slot:[`item.saldo_akhir_qty`]="{ item }">
                  <div style="font-weight: 700">
                    {{ formatNumber(item.saldo_akhir_qty) }}
                  </div>
                  <v-divider></v-divider>
                  <div style="font-size: 0.7rem; color: grey">
                    {{ formatNumber(item.saldo_akhir_rp) }}
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="detail.dialog"
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card outlined class="pb-4">
        <v-card-title class="py-2">
          <strong>{{ detail.namaBahan }}</strong> ({{ detail.kodeBahan }})
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card outlined>
            <v-data-table
              :headers="detail.headers"
              :items="detail.items"
              :loading="detail.loading"
              :search="detail.search"
              dense
            >
              <template v-slot:[`item.tanggal`]="{ item }">
                {{ formatDate(item.tanggal) }}
              </template>
              <template v-slot:[`item.jumlah`]="{ item }">
                {{ formatNumber(item.jumlah) }}
              </template>
              <template v-slot:[`item.harga`]="{ item }">
                {{ formatNumber(item.harga) }}
              </template>

              <template v-slot:[`item.subtotal`]="{ item }">
                {{ formatNumber(item.subtotal) }}
              </template>
              <template v-slot:[`item.saldo_balance_qty`]="{ item }">
                <strong> {{ formatNumber(item.saldo_balance_qty) }}</strong>
              </template>
              <template v-slot:[`item.saldo_balance_rp`]="{ item }">
                <strong> {{ formatNumber(item.saldo_balance_rp) }}</strong>
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  props: ["staPilih"],
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      data: {
        headers: [
          { text: "JENIS", value: "jenis", divider: true },
          { text: "MERK", value: "merk", divider: true },
          { text: "BARANG.", value: "nama_bahan", divider: true },
          {
            text: "SALDO AWAL",
            value: "saldo_awal_qty",
            divider: true,
            align: "right",
          },
          {
            text: "TERIMA",
            value: "terima_qty",
            divider: true,
            align: "right",
          },
          {
            text: "KELUAR",
            value: "keluar_qty",
            divider: true,
            align: "right",
          },
          {
            text: "SALDO AKHIR",
            value: "saldo_akhir_qty",
            divider: true,
            align: "right",
          },
          { text: "OPSI", value: "opsi" },
        ],
        items: [],
        loading: false,
        search: "",
        noBukti: "",
        index: "",
      },
      merk: {
        items: [],
        loading: false,
        search: "",
        value: "",
      },
      filter: {
        jenis: "",
      },
      detail: {
        dialog: false,
        headers: [
          { text: "TANGGAL", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "JENIS TRANS", value: "jns_trans", divider: true },
          { text: "NO. REF", value: "no_in", divider: true },
          { text: "JUMLAH", value: "jumlah", divider: true, align: "right" },
          { text: "HARGA", value: "harga", divider: true, align: "right" },
          { text: "SUBTOTAL", value: "subtotal", divider: true,align: "right" },
          { text: "SALDO QTY", value: "saldo_balance_qty", divider: true,align: "right" },
          { text: "SALDO RP", value: "saldo_balance_rp", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
        kodeBahan: "",
        namaBahan: "",
      },
    };
  },
  mounted() {
    this.loadData();
    this.loadFilterMerk();
  },
  methods: {
    async loadDetail(kode_bahan, nama_bahan) {
      this.detail.items = [];
      this.detail.loading = true;
      this.detail.dialog = true;
      this.detail.kodeBahan = kode_bahan;
      this.detail.namaBahan = nama_bahan;
      await axios
        .post("laporan/detailstokweb", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
          kode_bahan: kode_bahan,
        })
        .then((res) => {
          this.detail.items = res.data.data;
        });
      this.detail.loading = false;
    },
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("laporan/stok/web", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },

    async loadFilterMerk() {
      this.merk.items = [];
      this.merk.loading = true;
      await axios
        .post("laporan/stok/web/distinctmerk", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.merk.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.merk.loading = false;
    },
    setFilterSemua() {
      this.merk.value = "";
    },
    loadFilter() {
      this.loadData();
    },
    async fetchData() {
      const res = await axios.post("master/customer");
      return res.data.data;
    },
  },
  computed: {
    totalSaldoAwalQty() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.saldo_awal_qty;
      });
      return a;
    },
    totalSaldoAwalRp() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.saldo_awal_rp;
      });
      return a;
    },

    totalTerimaQty() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.terima_qty;
      });
      return a;
    },
    totalTerimaRp() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.terima_rp;
      });
      return a;
    },

    totalKeluarQty() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.keluar_qty;
      });
      return a;
    },
    totalKeluarRp() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.keluar_rp;
      });
      return a;
    },

    totalSaldoAkhirQty() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.saldo_akhir_qty;
      });
      return a;
    },
    totalSaldoAkhirRp() {
      let a = 0;
      this.filterData.forEach((element) => {
        a = a + element.saldo_akhir_rp;
      });
      return a;
    },
    filterData() {
      let data = "";
      if (this.merk.value == "") {
        data = this.data.items;
      } else {
        data = this.data.items.filter((el) => el.merk == this.merk.value);
      }

      return data;
    },
  },
};
</script>

<style></style>
