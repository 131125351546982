var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("RETURN PENERIMAAN PURCHASE ORDER (PO) KE SUPPLIER")])])],1),_c('v-divider'),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-card',[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"mr-4"},[_c('v-btn',{ref:"tambahData",attrs:{"color":"success","rounded":"","to":"/pembelian/return/input"}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v("Tambah Return")],1)],1),_c('div',{staticClass:"mr-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Awal","value":_vm.formatDate(_vm.periode.tanggal1),"append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.periode.pickerTanggal1),callback:function ($$v) {_vm.$set(_vm.periode, "pickerTanggal1", $$v)},expression:"periode.pickerTanggal1"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.loadData()},"input":function($event){_vm.periode.pickerTanggal1 = false}},model:{value:(_vm.periode.tanggal1),callback:function ($$v) {_vm.$set(_vm.periode, "tanggal1", $$v)},expression:"periode.tanggal1"}})],1)],1),_c('div',{staticClass:"mr-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Tanggal Akhir","value":_vm.formatDate(_vm.periode.tanggal2),"append-icon":"mdi-calendar","readonly":"","dense":"","outlined":"","hide-details":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.periode.pickerTanggal2),callback:function ($$v) {_vm.$set(_vm.periode, "pickerTanggal2", $$v)},expression:"periode.pickerTanggal2"}},[_c('v-date-picker',{on:{"change":function($event){return _vm.loadData()},"input":function($event){_vm.periode.pickerTanggal2 = false}},model:{value:(_vm.periode.tanggal2),callback:function ($$v) {_vm.$set(_vm.periode, "tanggal2", $$v)},expression:"periode.tanggal2"}})],1)],1),_c('div',[_c('v-text-field',{attrs:{"name":"name","label":"Pencarian","outlined":"","dense":"","hide-details":"","append-icon":"mdi-magnify","clearable":""},model:{value:(_vm.data.search),callback:function ($$v) {_vm.$set(_vm.data, "search", $$v)},expression:"data.search"}})],1),_c('v-spacer'),_c('div',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.loadData()}}},[_vm._v("REFRESH"),_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":_vm.data.headers,"items":_vm.data.items,"loading":_vm.data.loading,"search":_vm.data.search,"dense":""},scopedSlots:_vm._u([{key:"item.opsi",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.loadDetail(item.no_retur)}}},[_c('v-icon',[_vm._v("mdi-pen")])],1)]}},{key:"item.nama",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nama)+" ")]}},{key:"item.tanggal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.tanggal))+" ")]}},{key:"item.tot_qty",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.tot_qty))+" ")]}},{key:"item.tot_rp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.tot_rp))+" ")]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }