<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>LOG DATA</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col>
        <v-card outlined>
          <v-card-title class="d-flex justify-space-between">
            <v-text-field
              name="name"
              label="Pencarian"
              outlined
              ref="inputSearch"
              dense
              hide-details
              clearable
              append-icon="mdi-magnify"
              v-model="data.search"
            ></v-text-field>

            <div v-if="jenis != 'keluar'">
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Log Data"
                name="Log Data.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div>
            <div>
              <v-btn color="primary" text @click="loadData()"
                >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="data.items"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.initial`]="{ item }">
                  <div style="font-size:0.7rem;">{{ item.initial.substr(1, 70) }}</div>
                  
                </template>
                <template v-slot:[`item.updated`]="{ item }">
                  <div style="font-size:0.7rem;">{{ item.updated.substr(1, 70) }}</div>
                </template>

                <template v-slot:[`item.user`]="{ item }">
                  {{ item.user }}
                  <v-chip x-small label color="white">{{
                    item.ip_address
                  }}</v-chip>
                </template>
                <template v-slot:[`item.opsi`]="{ item }">
                  <v-btn
                    color="info"
                    small
                    rounded
                    text
                    @click="loadDetail(item.id)"
                    ><v-icon>mdi-eye</v-icon></v-btn
                  >
                </template>

                <template v-slot:[`item.rp_jual`]="{ item }">
                  <v-tooltip right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex justify-center">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="success"
                          small
                          rounded
                          text
                          ><v-icon color="primary"
                            >mdi-format-list-checkbox</v-icon
                          ></v-btn
                        >
                      </div>
                    </template>
                    <!-- Tooltip content -->
                    <div class="d-flex justify-end">
                      Distributor : Rp. {{ item.rp_distributor }}
                    </div>
                    <div class="d-flex justify-end">
                      Star-Outlet : Rp. {{ item.rp_staroutlet }}
                    </div>
                    <div class="d-flex justify-end">
                      Grosir : Rp. {{ item.rp_grosir }}
                    </div>
                    <div class="d-flex justify-end">
                      Retail : Rp. {{ item.rp_retail }}
                    </div>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="detail.dialog"
      max-width="1300px"
      transition="dialog-transition"
    >
      <v-card outlined>
        <v-card-title> ID : {{ data.id }} </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="detail.headers"
            :items="detail.items"
            :loading="detail.loading"
            :search="detail.search"
            dense
            hide-default-footer
          >
            <template v-slot:[`item.initial`]="{ item }">
              <div class="d-flex align-top">
                <table class="tabledetail">
                  <tbody>
                    <tr
                      v-for="(value, key) in JSON.parse(item.initial)"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td>
                        <div v-if="Array.isArray(value)">
                          <!-- <v-btn
                            color="info"
                            small
                            rounded
                            @click="showDetail2(value)"
                            ><v-icon>mdi-eye-outline</v-icon></v-btn
                          > -->
                          <v-card outlined>
                            <v-data-table
                              :headers="[
                                { text: 'KODE BARANG', value: 'kode_bahan' },
                                { text: 'JUMLAH', value: 'jumlah' },
                                { text: 'HARGA', value: 'rp_jual' },
                                { text: 'DISKON', value: 'diskon' },
                                { text: 'SUBTOTAL', value: 'subtotal' },
                              ]"
                              :items="value"
                              dense
                              hide-default-footer
                            >
                              <template v-slot:[`item.jumlah`]="{ item }">
                                {{ formatNumber(item.jumlah) }}
                              </template>
                              <template v-slot:[`item.rp_jual`]="{ item }">
                                {{ formatNumber(item.rp_jual) }}
                              </template>
                              <template v-slot:[`item.diskon`]="{ item }">
                                {{ formatNumber(item.diskon) }}
                              </template>
                              <template v-slot:[`item.subtotal`]="{ item }">
                                {{
                                  formatNumber(
                                    item.jumlah * item.rp_jual -
                                      item.jumlah * item.diskon
                                  )
                                }}
                              </template>
                            </v-data-table>
                          </v-card>
                        </div>
                        <div v-else>{{ value }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-slot:[`item.updated`]="{ item }">
              <div class="d-flex align-top">
                <table class="tabledetail">
                  <tbody>
                    <tr
                      v-for="(value, key) in JSON.parse(item.updated)"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td>
                        <div v-if="Array.isArray(value)">
                          <!-- <v-btn
                            color="info"
                            small
                            rounded
                            @click="showDetail2(value)"
                            ><v-icon>mdi-eye-outline</v-icon></v-btn
                          > -->
                          <v-card outlined>
                            <v-data-table
                              :headers="[
                                { text: 'KODE BARANG', value: 'kode_bahan' },
                                { text: 'JUMLAH', value: 'jumlah' },
                                { text: 'HARGA', value: 'rp_jual' },
                                { text: 'DISKON', value: 'diskon' },
                                { text: 'SUBTOTAL', value: 'subtotal' },
                              ]"
                              :items="value"
                              dense
                              hide-default-footer
                            >
                              <template v-slot:[`item.jumlah`]="{ item }">
                                {{ formatNumber(item.jumlah) }}
                              </template>
                              <template v-slot:[`item.rp_jual`]="{ item }">
                                {{ formatNumber(item.rp_jual) }}
                              </template>
                              <template v-slot:[`item.diskon`]="{ item }">
                                {{ formatNumber(item.diskon) }}
                              </template>
                              <template v-slot:[`item.subtotal`]="{ item }">
                                {{
                                  formatNumber(
                                    item.jumlah * item.rp_jual -
                                      item.jumlah * item.diskon
                                  )
                                }}
                              </template>
                            </v-data-table>
                          </v-card>
                        </div>
                        <div v-else>{{ value }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="details.dialog"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-col cols="auto" lg="6">
            <v-data-table
              :headers="[{ text: 'KODE BARANG', value: 'kode_bahan' }]"
              :items="details.initial"
            >
            </v-data-table>
          </v-col>
          <v-col cols="auto" lg="6">
            <v-data-table
              :headers="[{ text: 'KODE BARANG', value: 'kode_bahan' }]"
              :items="details.updated"
            >
            </v-data-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <notifications position="bottom right"></notifications>
  </v-container>
</template>

<script>
import axios from "axios";
// import swal from "sweetalert";
export default {
  props: ["staPilih", "jenis"],
  components: {},
  data() {
    return {
      data: {
        headers: [
          // { text: "ID", value: "id", divider: true },
          { text: "TANGGAL", value: "created_at", divider: true },
          { text: "USER & IP-ADDRESS", value: "user", divider: true },
          { text: "MODUL", value: "modul", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "JENIS", value: "clausa", divider: true },
          { text: "NILAI INITIAL", value: "initial", divider: true },
          { text: "NILAI UPDATED", value: "updated", divider: true },
          { text: "OPSI", value: "opsi", width: "5%" },
        ],

        items: [],
        loading: false,
        search: "",
        id: "",
      },
      detail: {
        dialog: false,
        headers: [
          { text: "INITIAL", value: "initial" },
          { text: "UPDATED", value: "updated" },
        ],
        items: [],
        loading: false,
        search: "",
      },
      details: {
        initial: [],
        updated: [],
        dialog: false,
      },
    };
  },
  mounted() {
    this.loadData();
    this.$refs.inputSearch.focus();
  },
  methods: {
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("log")
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          console.log(err);
        });

      this.data.loading = false;
    },
    async loadDetail(id) {
      this.detail.items = [];
      this.detail.dialog = true;
      this.data.id = id;
      this.detail.loading = true;
      await axios
        .post("log/detail", {
          id: id,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.detail.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          console.log(err);
        });
      this.detail.loading = false;
    },
    showDetail2(initial, updated) {
      this.details.initial = initial;
      this.details.updated = updated;
      this.details.dialog = true;
    },
    async fetchData() {
      const res = await axios.post("log");
      return res.data.data;
    },
  },
  computed: {
    // initialObj() {
    //     return Object.entries(this.detail.items.initial)
    // }
  },
};
</script>

<style scoped>
.tabledetail {
  border-collapse: collapse;
  margin: 12px 0;
  font-size: 1.1em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
}

.tabledetail thead tr,
th {
  background-color: #f1f1f1;
  color: #1a1919;
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #303030;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
  border-bottom: 1px solid #8b8b8b;
}
.tabledetail th,
.tabledetail td {
  padding: 3px 12px;
}
.tabledetail tbody tr td {
  border-bottom: 1px solid #303030;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}
/* .tabledetail tbody tr:nth-of-type(even) {
  background: #f3f3f3;
} */
.tabledetail tbody tr:last-of-type {
  border-bottom: 2px solid #525252;
}
</style>
