<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-space-around">
        <h2>LAPORAN RETURN PENJUALAN</h2>
        <v-spacer></v-spacer>
        <div>
          <v-btn color="primary" text @click="loadData()"
            >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-2">
      <v-col class="d-flex justify-start">
        <div class="mr-2">
          <v-menu
            v-model="periode.pickerTanggal1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tanggal Awal"
                :value="formatDate(periode.tanggal1)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="periode.tanggal1"
              @change="loadData()"
              @input="periode.pickerTanggal1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="mr-2">
          <v-menu
            v-model="periode.pickerTanggal2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                label="Tanggal Akhir"
                :value="formatDate(periode.tanggal2)"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                hide-details
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="periode.tanggal2"
              @change="loadData()"
              @input="periode.pickerTanggal2 = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-1">
      <!-- TOTAL NOTA TAGIHAN -->
      <v-col cols="auto" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-title class="py-4">
            <v-btn color="primary" dark elevation="0" small fab class="mr-3"
              ><v-icon>mdi-text-box</v-icon></v-btn
            >
            <div class="text-h5">
              {{ formatNumber(totalTagihan) }}
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="py-1">
            <div class="text-subtitle-1">Total Return</div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <div>
              <v-text-field
                label="Pencarian"
                outlined
                dense
                hide-details
                v-model="data.search"
                clearable
                append-icon="mdi-magnify"
                class="mr-2"
              ></v-text-field>
            </div>
            <div>
              <v-btn-toggle
                rounded
                v-model="filter.jenis"
                color="info"
                mandatory
                class="mr-2"
              >
                <v-btn small value="semua"> Semua ({{ countTagihan }}) </v-btn>
              </v-btn-toggle>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="success" rounded @click="download()"
              >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
            >
            <!-- <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Return Penjualan"
                name="Data Return Penjualan.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div> -->
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="filterData"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.tanggal`]="{ item }">
                  <v-chip label outlined x-small>{{
                    formatDate(item.tanggal)
                  }}</v-chip>
                </template>
                <template v-slot:[`item.tgl_validasi`]="{ item }">
                  <v-chip label outlined x-small>{{
                    formatDate(item.tgl_validasi)
                  }}</v-chip>
                </template>
                <template v-slot:[`item.no_bukti`]="{ item }">
                  <strong> {{ item.no_bukti }}</strong>
                </template>
                <template v-slot:[`item.alamat`]="{ item }">
                  <div style="font-size: 0.7rem">{{ item.alamat }}</div>
                </template>
                <template v-slot:[`item.barang`]="{ item }">
                  <div>{{ item.kode_bahan }}</div>
                  <div>
                    <strong>{{ item.nama_bahan }}</strong>
                    <v-chip outlined small>{{ item.unit }}</v-chip>
                  </div>
                </template>
                <template v-slot:[`item.jumlah`]="{ item }">
                  {{ formatNumber(item.jumlah) }}
                </template>
                <template v-slot:[`item.harga`]="{ item }">
                  {{ formatNumber(item.harga) }}
                </template>
                <template v-slot:[`item.diskon`]="{ item }">
                  {{ formatNumber(item.diskon) }}
                </template>
                <template v-slot:[`item.subtotal`]="{ item }">
                  {{ formatNumber(item.subtotal) }}
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="detail.dialog"
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card outlined class="pb-4">
        <v-card-title class="py-2">
          <strong>{{ detail.nama }}</strong> ({{ detail.kodeCustomer }})
        </v-card-title>
        <!-- <v-divider></v-divider> -->
        <v-card-text>
          <v-card outlined>
            <v-data-table
              :headers="detail.headers"
              :items="detail.items"
              :loading="detail.loading"
              :search="detail.search"
              dense
            >
              <template v-slot:[`item.tanggal`]="{ item }">
                {{ formatDate(item.tanggal) }}
              </template>
              <template v-slot:[`item.debit`]="{ item }">
                {{ formatNumber(item.debit) }}
              </template>
              <template v-slot:[`item.kredit`]="{ item }">
                {{ formatNumber(item.kredit) }}
              </template>
              <template v-slot:[`item.saldo`]="{ item }">
                {{ formatNumber(item.saldo) }}
              </template>
            </v-data-table>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import ExcelJS from "exceljs";

export default {
  props: ["staPilih"],
  data() {
    return {
      periode: {
        pickerTanggal1: false,
        tanggal1:
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 7) + "-01",
        pickerTanggal2: false,
        tanggal2: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      },
      data: {
        headers: [
          { text: "TGL. RETUR", value: "tanggal", divider: true },
          { text: "NAMA", value: "nama", divider: true },
          { text: "NO. BUKTI", value: "no_retur", divider: true },
          { text: "NO. NOTA", value: "no_invoice", divider: true },
          { text: "KODE OUTLET", value: "kode_outlet", divider: true },
          { text: "SALES", value: "sales", divider: true },
          {
            text: "KETERANGAN",
            value: "keterangan",
            show: false,
            divider: true,
          },
          { text: "BARANG", value: "nama_bahan", divider: true },
          {
            text: "JUMLAH",
            value: "jumlah",
            divider: true,
            align: "right",
          },
          {
            text: "HARGA",
            value: "harga",
            divider: true,
            align: "right",
          },
          {
            text: "SUBTOTAL",
            value: "subtotal",
            divider: true,
            align: "right",
          },
        ],
        items: [],
        loading: false,
        search: "",
        noBukti: "",
        index: "",
      },
      filter: {
        jenis: "",
      },
      detail: {
        dialog: false,
        headers: [
          { text: "TGL. NOTA", value: "tanggal", divider: true },
          { text: "NO. BUKTI", value: "no_bukti", divider: true },
          { text: "NO. REF", value: "no_ref", divider: true },
          { text: "KET", value: "keterangan", divider: true },
          // { text: "NAMA", value: "nama", divider: true },
          { text: "DEBIT", value: "debit", divider: true, align: "right" },
          { text: "KREDIT", value: "kredit", divider: true, align: "right" },
          { text: "SALDO", value: "saldo", align: "right" },
        ],
        items: [],
        loading: false,
        search: "",
        kodeCustomer: "",
        nama: "",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadDetail(kode_customer, nama) {
      this.detail.items = [];
      this.detail.loading = true;
      this.detail.dialog = true;
      this.detail.kodeCustomer = kode_customer;
      this.detail.nama = nama;
      await axios
        .post("penjualan/piutang/detail", {
          kode_customer: kode_customer,
        })
        .then((res) => {
          this.detail.items = res.data.data;
        });
      this.detail.loading = false;
    },
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      await axios
        .post("laporan/returnpenjualan", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },

    // PENJUALAN
    async download() {
      try {
        // Make an API request to fetch data (replace 'apiEndpoint' with your actual API endpoint)
        const response = await axios.post("laporan/returnpenjualan", {
          tanggal1: this.periode.tanggal1,
          tanggal2: this.periode.tanggal2,
        });
        // Create a new Excel workbook and worksheet
        const workbook = new ExcelJS.Workbook();

        const worksheet = workbook.addWorksheet("DETAIL");

        // TITLE
        const titleRow = worksheet.addRow([
          "LAPORAN RETURN PENJUALAN " +
            localStorage.getItem("kode_company") +
            " - PERIODE : " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " s/d " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY"),
        ]);
        titleRow.font = { bold: true };
        worksheet.addRow([""]);

        // HEADER
        const headerRow = worksheet.addRow([
          "CUSTOMER",
          "KODE CUSTOMER",
          "TGL. RETUR",
          "NO. RETUR",
          "NO. NOTA",
          "KODE OUTLET",
          "TIPE CUSTOMER",
          "ALAMAT",
          "NAMA SALES",
          "KODE PRODUK",
          "NAMA PRODUK",
          "ITEM PRODUK",
          "MERK PRODUK",
          "SATUAN",
          "JUMLAH",
          "HARGA JUAL",
          "SUBTOTAL",
        ]);
        headerRow.font = { bold: true };

        //FETCH DETAIL
        response.data.data.forEach((item) => {
          worksheet.addRow([
            item.nama,
            item.kode_customer,
            moment(String(item.tanggal)).format("DD/MM/YYYY"),
            item.no_retur,
            item.no_invoice,
            item.kode_outlet,
            item.jenis,
            item.alamat,
            item.sales,
            item.kode_bahan,
            item.nama_bahan,
            item.ukuran,
            item.merk,
            item.unit,
            item.jumlah,
            item.harga,
            item.subtotal,
          ]);
        });

        worksheet.mergeCells("A1:E1");
        worksheet.getColumn(15).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        worksheet.getColumn(16).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';
        worksheet.getColumn(17).numFmt =
          '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

        // Save the workbook to a file or download it
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download =
            "Laporan Return Penjualan " +
            localStorage.getItem("kode_company") +
            " Periode " +
            moment(String(this.periode.tanggal1)).format("DD-MM-YYYY") +
            " - " +
            moment(String(this.periode.tanggal2)).format("DD-MM-YYYY") +
            ".xlsx";
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        });
      } catch (error) {
        console.error("Error fetching data from the API", error);
      }
    },
  },
  computed: {
    totalTagihan() {
      let a = 0;
      this.data.items.forEach((element) => {
        a = a + element.subtotal;
      });
      return a;
    },
    countTagihan() {
      let a = 0;
      this.data.items.forEach(() => {
        a++;
      });
      return a;
    },
    filterData() {
      let data = "";
      if (this.filter.jenis == "lunas") {
        data = this.data.items.filter((el) => el.rp_outstand <= 0);
      } else if (this.filter.jenis == "belum") {
        data = this.data.items.filter((el) => el.rp_outstand > 0);
      } else {
        data = this.data.items;
      }

      return data;
    },
  },
};
</script>

<style></style>
