<template>
  <v-container>
    <v-row>
      <v-col>
        <h2>MASTER REKENING</h2>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <!-- <v-row class="mt-2">
        <v-col>
          <v-card outlined>
            <v-card-title>
              Filter Data
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text> </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->
    <v-row class="mt-2">
      <v-col>
        <v-card outlined>
          <v-card-title class="d-flex justify-space-between">
            <div class="mr-4">
              <v-btn
                color="success"
                rounded
                @click="
                  (addData.dialog = true), resetForm(), (staUpdate = false)
                "
                v-if="!staPilih"
                ><v-icon class="mr-2">mdi-plus-thick</v-icon>Tambah Data</v-btn
              >
            </div>
            <v-text-field
              name="name"
              label="Pencarian"
              outlined
              dense
              hide-details
              v-model="data.search"
            ></v-text-field>
            <v-spacer></v-spacer>
            <div>
              <download-excel
                class="btn btn-default"
                :fetch="fetchData"
                worksheet="Data Rekening Akun"
                name="Data Rekening Akun.xls"
              >
                <v-btn color="success" text
                  >Excel<v-icon>mdi-table-arrow-down</v-icon></v-btn
                >
              </download-excel>
            </div>
            <div>
              <v-btn color="primary" text @click="loadData()"
                >REFRESH<v-icon>mdi-refresh</v-icon></v-btn
              >
            </div>
          </v-card-title>
          <v-card-text>
            <v-card outlined>
              <v-data-table
                :headers="data.headers"
                :items="data.items"
                :loading="data.loading"
                :search="data.search"
                dense
              >
                <template v-slot:[`item.opsi`]="{ item }">
                  <v-btn
                    color="primary"
                    text
                    @click="
                      pilihRekening(
                        item.kode_rekening,
                        item.nama_rekening,
                        item.jenis,
                        item.xjenis
                      )
                    "
                    v-if="staPilih"
                    ><v-icon>mdi-check-bold</v-icon></v-btn
                  >
                  <div v-else>
                    <v-btn
                      color="primary"
                      text
                      @click="loadUpdate(item.kode_rekening)"
                      ><v-icon>mdi-pen</v-icon></v-btn
                    >
                    <v-btn
                      color="error"
                      text
                      @click="deleteData(item.kode_rekening)"
                      ><v-icon>mdi-close-thick</v-icon></v-btn
                    >
                  </div>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="addData.dialog"
      width="1100px"
      transition="dialog-transition"
    >
      <v-card class="pb-5">
        <v-card-title> Tambah Data </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-form v-model="addData.isValid">
            <v-text-field
              v-model="addData.form.kode"
              label="(Otomatis) Kode Rekening"
              dense
              outlined
              readonly
              placeholder="Otomatis"
              background-color="#f7f7f7"
            ></v-text-field>
            <v-radio-group
              :rules="addData.rules.jenis"
              v-model="addData.form.jenis"
              row
              dense
              :disabled="staUpdate"
              label="Jenis : "
            >
              <v-radio label="Penerimaan" value="terima"></v-radio>
              <v-radio label="Pengeluaran" value="keluar"></v-radio>
            </v-radio-group>
            <v-radio-group
              :rules="addData.rules.groupakun"
              v-model="addData.form.groupakun"
              row
              dense
              label="Group Akun : "
            >
              <v-radio label="General" value="general"></v-radio>
              <v-radio label="Customer" value="customer"></v-radio>
              <v-radio label="Supplier" value="supplier"></v-radio>
            </v-radio-group>
            <v-text-field
              :rules="addData.rules.nama"
              v-model="addData.form.nama"
              label="Nama"
              dense
              outlined
            ></v-text-field>
            <v-divider></v-divider>
            <div class="d-flex justify-end mt-2">
              <v-btn
                color="success"
                v-if="!staUpdate"
                :disabled="!addData.isValid"
                @click="createData()"
                >SIMPAN<v-icon>mdi-floppy</v-icon></v-btn
              >
              <v-btn
                v-else
                color="warning"
                :disabled="!addData.isValid"
                @click="updateData()"
                >UPDATE<v-icon>mdi-floppy</v-icon></v-btn
              >
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <notifications position="bottom right"></notifications>
  </v-container>
</template>
  
  <script>
import axios from "axios";
import swal from "sweetalert";
export default {
  props: ["staPilih", "jenis"],
  data() {
    return {
      data: {
        headers: [
          { text: "KODE REKENING", value: "kode_rekening", divider: true },
          { text: "JENIS", value: "jenis", width: "50%", divider: true },
          { text: "NAMA", value: "nama_rekening", divider: true },
          { text: "GROUP AKUN", value: "xjenis", divider: true },
          { text: "OPSI", value: "opsi" },
        ],
        items: [],
        loading: false,
        search: "",
      },

      addData: {
        dialog: false,
        isValid: false,
        form: {
          kode: "",
          nama: "",
          jenis: null,
          groupakun: "",
        },
        rules: {
          nama: [(v) => v != "" || "Nama Harus Diisi"],
          jenis: [(v) => v != null || "Jenis Harus Dipilih"],
          groupakun: [(v) => v != null || "Group Harus Dipilih"],
        },
        loading: false,
      },
      dialogJenisBarang: false,
      staUpdate: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.data.items = [];
      this.data.loading = true;
      console.log(this.jenis);
      await axios
        .post("master/rekening", {
          jenis: this.jenis,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.data.items = res.data.data;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });

      this.data.loading = false;
    },
    async createData() {
      this.addData.loading = true;
      await axios
        .post("master/rekening/create", this.addData.form)
        .then((res) => {
          if (res.status != 200) {
            swal("Gagal", res.data.error, "error");
            return;
          }
          swal("Sukses", res.data.message, "success");
          this.addData.dialog = false;
          this.resetForm();
          this.loadData();
        })
        .catch((err) => {
          console.log(err);
          swal("Gagal", "Ada Kesalahan Server (404)", "error");
        });
      this.addData.loading = false;
    },
    resetForm() {
      this.addData.form = {
        nama: "",
        jenis: null,
      };
    },
    async loadUpdate(kode_rekening) {
      this.resetForm();
      this.staUpdate = true;
      await axios
        .post("master/rekening/bykode", {
          kode_rekening: kode_rekening,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({ type: "error", text: res.data.error });
            return;
          }
          this.addData.dialog = true;
          console.log(res.data.data);
          this.addData.form.kode = res.data.data.kode_rekening;
          this.addData.form.nama = res.data.data.nama_rekening;
          this.addData.form.jenis = this.getJenis(res.data.data.jenis);
          this.addData.form.groupakun = res.data.data.groupakun;
          this.$notify({ type: "success", text: res.data.message });
        })
        .catch((err) => {
          this.$notify({ type: "error", text: err });
        });
    },
    getJenis(jenis) {
      if (jenis.toUpperCase() == "IN") {
        return "terima";
      } else if (jenis.toUpperCase() == "OU") {
        return "keluar";
      }
    },
    async updateData() {
      console.log(this.addData.form);
      await axios
        .post("master/rekening/update", this.addData.form)
        .then((res) => {
          swal("Sukses", res.data.message, "success");
          this.addData.dialog = false;
          this.resetForm();
          this.loadData();
        })
        .catch((err) => {
          console.log(err);
          swal("Gagal", "Ada Kesalahan Server (404)", "error");
        });
    },
    async deleteData(kode_rekening) {
      swal("Yakin Hapus Data Rekening :   " + kode_rekening, "Data Tidak Bisa Dikembalikan", {
        buttons: {
          tidak: true,
          delete: true,
        },
      }).then((value) => {
        switch (value) {
          case "delete":
            axios
              .post("master/rekening/delete", {
                kode_rekening: kode_rekening,
              })
              .then((res) => {
                swal("Sukses", res.data.message, "success");
                this.loadData();
              })
              .catch((err) => {
                console.log(err);
                swal("Gagal", "Ada Kesalahan Server (404)", "error");
              });
            break;

          default:
            break;
        }
      });
    },
    async fetchData() {
      const res = await axios.post("master/rekening");
      return res.data.data;
    },
    pilihRekening(kode_rekening, nama_rekening, jenis, xjenis) {
      this.$emit("rekening", {
        kode_rekening: kode_rekening,
        nama_rekening: nama_rekening,
        jenis: jenis,
        xjenis: xjenis,
      });
    },
  },
  computed: {},
};
</script>
  
  <style></style>
  