<template>
  <div>
    <v-btn color="success" @click="loadPrintInvoice()">Generate PDF</v-btn>
    <!-- CETAK INVOICE -->
    <VueHtml2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="myPDF"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="legal"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <v-app>
          <v-main>
            <v-container>
              <div>
                <v-card elevation="0">
                  <v-col class="pa-0">
                    <v-card-title class="py-1 d-flex justify-space-around">
                      <v-img :src="setLogo()" max-width="120px" height="80px">
                      </v-img>
                      <v-spacer></v-spacer>
                      <div class="text-h6">
                        <strong> {{ invoice.items[0].kode_outlet }}</strong>
                      </div>
                      <!-- <v-chip label outlined color="black" large>
              <div class="text-h5">NOTA PENJUALAN</div></v-chip> -->
                    </v-card-title>
                    <hr />
                    <v-card-text class="pt-1">
                      <!-- HEADER -->
                      <div class="d-flex justify-space-around">
                        <table>
                          <tr>
                            <td>No. Nota</td>
                            <td>: {{ invoice.items[0].no_bukti }}</td>
                          </tr>
                          <tr>
                            <td>Pembayaran</td>
                            <td>
                              : {{ invoice.items[0].pembayaran.toUpperCase() }}
                            </td>
                          </tr>
                          <tr>
                            <td>Tanggal</td>
                            <td>
                              : {{ formatDate2(invoice.items[0].tgl_update) }}
                            </td>
                          </tr>
                        </table>
                        <v-spacer></v-spacer>
                        <table>
                          <tr>
                            <td>Kepada Yth,</td>
                          </tr>
                          <tr>
                            <td>{{ invoice.items[0].nama }}</td>
                          </tr>
                          <tr>
                            <td class="text-caption">
                              {{ invoice.items[0].telepon }}
                            </td>
                          </tr>
                          <tr>
                            <td class="text-caption">
                              {{ invoice.items[0].alamat }},
                              {{ invoice.items[0].kecamatan }},
                              {{ invoice.items[0].kabupaten }}
                            </td>
                          </tr>
                        </table>
                      </div>

                      <!-- <v-divider class="my-2"></v-divider> -->
                      <!-- DETAIL -->
                      <table width="100%" class="tabledetail mt-4">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Nama Barang</th>
                            <th>Satuan</th>
                            <th>Jumlah</th>
                            <th>Harga Satuan</th>
                            <th>Subtotal</th>
                            <th>Diskon</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in invoice.items"
                            :key="item.urut"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.nama_bahan }}</td>
                            <td>{{ item.unit }}</td>
                            <td align="right">
                              {{ formatNumber(item.jumlah) }}
                            </td>
                            <td align="right">
                              {{ formatNumber(item.rp_jual) }}
                            </td>
                            <td align="right">
                              {{ formatNumber(item.jumlah * item.rp_jual) }}
                            </td>
                            <td align="right">
                              <!-- {{ formatNumber(item.jumlah * item.diskon_item) }} -->
                              <!-- @{{ formatNumber(item.diskon_item) }} = -->
                              {{ formatNumber(item.jumlah * item.diskon_item) }}
                            </td>
                          </tr>
                          <tr>
                            <td align="right" colspan="6">Total Rp.</td>
                            <td align="right">
                              <strong>
                                {{
                                  formatNumber(invoice.items[0].total_kotor)
                                }}</strong
                              >
                            </td>
                          </tr>
                          <tr>
                            <td align="right" colspan="6">Total Diskon Rp.</td>
                            <td align="right">
                              <strong>{{
                                formatNumber(invoice.items[0].diskon)
                              }}</strong>
                            </td>
                          </tr>
                          <tr>
                            <td align="right" colspan="6">
                              Total Pembayaran Rp.
                            </td>
                            <td align="right">
                              <strong>{{
                                formatNumber(invoice.items[0].total_net)
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- TOTAL -->
                      <div
                        v-if="
                          invoice.items[0].pembayaran.toUpperCase() == 'KREDIT'
                        "
                      >
                        <strong
                          >Tanggal Jatuh Tempo :
                          {{
                            formatDate2(invoice.items[0].tgl_jatuhtempo)
                          }}</strong
                        >
                      </div>
                      <div class="mt-2">
                        <strong>
                          <div>Pembayaran Melalui :</div>
                          <div>Nurulliana Maharsi BCA 0301219015</div>
                        </strong>
                      </div>

                      <div class="d-flex justify-space-between">
                        <v-col cols="3">
                          <div class="d-flex justify-center">Penerima</div>
                          <v-spacer class="my-12"></v-spacer>
                          <div class="d-flex justify-center">
                            <strong></strong>
                          </div>
                          <hr />

                          <div class="d-flex justify-center">Nama, TTD/Cap</div>
                        </v-col>
                        <v-col cols="3">
                          <div class="d-flex justify-center">Hormat Kami</div>
                          <v-spacer class="my-7"></v-spacer>

                          <div class="d-flex justify-center">
                            <strong> {{ invoice.items[0].user }}</strong>
                          </div>
                          <hr />
                          <div class="d-flex justify-center">
                            Petugas Pemasaran
                          </div>
                        </v-col>
                      </div>
                      <v-divider class="my-2"></v-divider>
                    </v-card-text>
                  </v-col>
                </v-card>
              </div>
            </v-container>
          </v-main>
        </v-app>
      </section>
    </VueHtml2pdf>

    <!-- END CETAK INVOICE -->
  </div>
</template>

<script>
import axios from "axios";
import VueHtml2pdf from "vue-html2pdf";
export default {
  data() {
    return {
      components: {
        VueHtml2pdf,
      },
      invoice: {
        dialog: false,
        items: [
          {
            no_bukti: "",
            pembayaran: "",
            user: "",
            tgl_update: "",
            total_kotor: "",
            total_net: "",
            diskon: "",
            bayar: "",
            kembali: "",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    async loadPrintInvoice() {
      await axios
        .post("penjualan/transaksi/print", {
          noBukti: "0455/TR/01/2024",
        })
        .then((res) => {
          if (res.status != 200) {
            this.$notify({
              type: "error",
              text: res.data.message,
            });
            return;
          }
          this.invoice.items = res.data.data;
          setTimeout(() => {
            this.$refs.html2Pdf.generatePdf();
          }, 4000);
        });
    },
    setLogo() {
      return "https://api.sinarpanganfood.com/public/SPF.png";
    },
  },
};
</script>

<style>
</style>